import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {reduxForm, formValueSelector} from 'redux-form'
import {Redirect} from 'react-router'
import {push} from 'connected-react-router'
import {gql} from '@apollo/client'
import classNames from 'classnames'
import moment from 'moment'
import Button from 'jbc-front/components/Button'
import {TextAreaField} from 'jbc-front/components/Form'
import {onSubmitFail} from 'jbc-front/components/FormErrors'
import Hint from 'jbc-front/components/Hint'
import {maxLength} from 'validators'
import {useMutation, useQuery} from 'components/Graphql'
import LoadingPage from 'components/LoadingPage'
import {DOCUMENT_FRAGMENT} from 'documents/show/Query'
import Agreement from 'documents/contract/Agreement'
import ContractTargetList from 'documents/contract/ContractTargetList'
import {ContractTargetWarnings} from 'documents/contract/ContractTargetWarnings'
import styles from 'documents/Contract.scss'
import {getMainOfficeContactInfo} from 'offices/utils'
import {notifySuccess, notifyError} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const formName = 'sendContractForm'
const selector = formValueSelector(formName)

const allowedPlanForSendContract = 'paid_plan'

const CONTRACT_DOCUMENT_FRAGMENT = gql`
  fragment ContractDocumentFields on Document {
    id
    title
    visibility
    contractSending
    contractSent
    existContractTargets
    documentContractRequest {
      id
      status
    }
    createdUser {
      id
      name
      employee {
        id
        displayFirstName
        displayLastName
      }
    }
  }
`

const CONTRACT_DOCUMENT = gql`
  query document($id: ID!) {
    client {
      id
      planType
      isVerified
      document(id: $id) {
        ...ContractDocumentFields
      }
    }
  }
  ${CONTRACT_DOCUMENT_FRAGMENT}
`

const CREATE_CONTRACT = gql`
  mutation createContract($input: CreateContractInput!) {
    createContract(input: $input) {
      document {
        ...DocumentFields
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`

const SIGN_FREE_PERIOD_START_ON = '2023-01-10 10:00'
const SIGN_FREE_PERIOD_END_ON = '2023-02-28 23:59'
export const isSignFreePeriod = (current) =>
  moment(current).isBetween(SIGN_FREE_PERIOD_START_ON, SIGN_FREE_PERIOD_END_ON, 'minute', '[)')

const isSendableContract = (currentUser, document) =>
  currentUser && (document.createdUser?.id == currentUser.id || document.visibility === 'all_admins')

const makeErrorMessage = (clientPlanType, isVerifiedClient, currentUser, document) => {
  if (!document) {
    return '書類がありません'
  }
  if (clientPlanType !== allowedPlanForSendContract) {
    return '有料プランにお申し込みいただくことで、ご利用いただけます'
  }
  if (!isVerifiedClient) {
    return 'メール送信機能が制限されているため、電子契約を送信できません'
  }

  if (!isSendableContract(currentUser, document)) {
    return '該当操作を行う権限がありません'
  }

  if (document.contractSending || document.contractSent || document.documentContractRequest?.status === 'failed') {
    return '電子契約は送信済です'
  }

  if (!document.existContractTargets) {
    return '電子契約を送信可能な従業員が存在しません'
  }

  return ''
}

export const Section = ({children}) => <div className="u-mb20">{children}</div>

export const Label = ({children}) => <div className={styles.label}>{children}</div>

const ContractForm = ({handleSubmit, currentUser, document}) => {
  const currentOffice = useSelector((state) => state.client.current.office)
  const senderClientName = getMainOfficeContactInfo(currentOffice).name

  return (
    <form onSubmit={handleSubmit}>
      <div className="u-mb20">
        <p>以下の内容で電子契約署名依頼メールを送信します。</p>
        <p className={styles.annotation}>※電子契約はメールアドレスが設定されている従業員に対してのみ送信されます</p>
      </div>
      <div className={styles.contractInfoBox}>
        <Section>
          <Label>差出人</Label>
          <p className={styles.text}>
            {senderClientName} {currentUser.name || currentUser.employee.full_name}
          </p>
        </Section>
        <Section>
          <Label>書類名</Label>
          <p className={styles.text}>{document.title}</p>
        </Section>
      </div>
      <div className={styles.commentBox}>
        <Label>
          コメント
          <Hint text="署名依頼メール本文に送信先へのコメントを記載できます" width={260} messageLeft={-65} baseLine />
        </Label>
        <TextAreaField name="comment" label="コメント" noLabel description="最大2000文字" validate={maxLength(2000)} />
      </div>
    </form>
  )
}

const Form =
  (({handleSubmit, submitting, invalid, change, dispatch, search, pathname, id, history}) => {
    const currentUser = useSelector((state) => state.session.currentUser)
    const {data, error, loading} = useQuery(CONTRACT_DOCUMENT, {
      fetchPolicy: 'network-only',
      variables: {id: id},
    })
    const clientPlanType = data?.client?.planType
    const document = data?.client?.document
    const errorMessage = makeErrorMessage(clientPlanType, data?.client?.isVerified, currentUser, document)

    if (error) {
      return <Redirect to="/documents" />
    }

    if (loading) {
      return <LoadingPage />
    }

    if (errorMessage) {
      dispatch(notifyError(errorMessage))
      return <Redirect to="/documents" />
    }

    return (
      <>
        <ContractForm handleSubmit={handleSubmit} currentUser={currentUser} document={document} />
        <ContractTargetWarnings documentId={id} />
        <div>
          <h4 className={classNames('u-mb10', styles.summaryHeader)}>送信先一覧</h4>
          <ContractTargetList search={search} pathname={pathname} id={id} history={history} currentUser={currentUser} />
        </div>
        <div className="u-ta-c">
          <span className={styles.button}>
            <Button onClick={() => dispatch(push(`/documents/${id}`))}>キャンセル</Button>
          </span>
          <span className={styles.button}>
            <Agreement
              handleSubmit={handleSubmit}
              submitting={submitting}
              change={change}
              disabled={invalid}
              selector={selector}
            />
          </span>
        </div>
      </>
    )
  })
  |> reduxForm({
    form: formName,
    enableReinitialize: true,
    onSubmitFail,
  })

const Contract = ({
  location: {search, pathname},
  match: {
    params: {id},
  },
  history,
}) => {
  const dispatch = useDispatch()
  const [createContract] = useMutation(CREATE_CONTRACT)
  const isSignFree = isSignFreePeriod(moment())
  const handleSubmit = useCallback(
    async ({...values}) => {
      try {
        await createContract({
          variables: {
            input: {
              id,
              comment: values?.comment,
              isPublished: values?.isPublished,
            },
          },
        })
        dispatch(push(`/documents/${id}`))
        dispatch(notifySuccess('送信しました'))
        if (values?.isPublished) {
          dispatch(notifySuccess('書類を公開しました'))
        }
      } catch (err) {
        dispatch(push('/documents'))
        dispatch(asyncError(err))
      }
    },
    [id]
  )

  return (
    <>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">電子契約送信</h1>
        <p className={classNames('m-title-main-note', isSignFree && styles.freePeriod)}>
          「送信する」をクリックすると1件毎に定価200円（税抜）が発生します。
        </p>
        {isSignFree && (
          <p className={styles.annotation}>キャンペーン期間（2023年1月～2月）につき電子契約利用料が無料</p>
        )}
      </div>
      <div className="l-wrap-xl l-contents-wrap">
        <Form {...{dispatch, search, pathname, id, history}} onSubmit={handleSubmit} />
      </div>
    </>
  )
}

export default Contract
