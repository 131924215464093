import {combineEpics} from 'redux-observable'
import {actionTypes} from 'actions'
import {
  switchMap,
  mergeMap,
  groupBy,
  takeUntil,
  throttleTime,
  debounceTime,
  filter,
  map,
  catchError,
} from 'rxjs/operators'
import {of, from} from 'rxjs'
import {notify} from 'reapop'
import {submit} from 'redux-form'
import {formName as employeeSearchForm} from 'employees/list/utils'
import {formName as documentEmployeeSearchForm} from 'documentEmployees/list/utils'
import {formName as tmEmployeeSearchForm} from 'tm/employees/list/utils'
import {formName as documentEmployeeSelectSearchForm} from 'documents/create/list/utils'
import {formName as auditLogSearchForm} from 'settings/auditLogs/list/utils'
import {formName as usersClientsSearchForm} from 'settings/clientRoles/utils'
import {formName as notificationSearchForm} from 'notifications/list/utils'
import {formName as tmUserSearchForm} from 'settings/roles/employeeList/utils'
import {notifyError} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const fetchEpic = (actions) => (action$) =>
  action$.ofType(actions.FETCH_DATA)
  |> switchMap(
    (action) =>
      from(action.payload)
      |> takeUntil(action$.ofType(actions.DESTROY))
      |> map((data) => ({type: actions.FETCH_SUCCESSED, payload: data}))
      |> catchError((err) => of(asyncError(err), {type: actions.FETCH_FAILED}))
  )

const fetchEveryEpic = (actions) => (action$) =>
  action$.ofType(actions.FETCH_DATA)
  |> mergeMap(
    (action) =>
      from(action.payload)
      |> takeUntil(action$.ofType(actions.DESTROY))
      |> map((data) => ({type: actions.FETCH_SUCCESSED, payload: data}))
      |> catchError((err) => of(asyncError(err), {type: actions.FETCH_FAILED}))
  )

const mergeErrorEpic = (action$) =>
  action$.ofType(notifyError.type)
  |> groupBy((action) => action.payload)
  |> mergeMap(
    ($act) =>
      $act
      |> throttleTime(500)
      |> map((action) =>
        notify({
          message: action.payload,
          position: 'top-center',
          status: 'error',
          dismissAfter: 3000,
        })
      )
  )

const fetchDataEpic = (action$) =>
  action$.ofType('FETCH_DATA')
  |> groupBy((action) => action.meta.name)
  |> mergeMap(
    ($acti) =>
      $acti
      |> switchMap(
        (action) =>
          from(action.meta.promise)
          |> takeUntil(
            action$.ofType(action$.ofType('FETCH_DESTROY') |> filter((act) => act.meta.name === action.meta.name))
          )
          |> map((data) => ({...action, type: 'FETCH_SUCCESSED', payload: data}))
          |> catchError((err) => of(asyncError(err), {...action, type: 'FETCH_FAILED', payload: err}))
      )
  )

const search =
  (formName, excludeFields = ['limit', 'sort_type', 'page']) =>
  (action$) => {
    const formActions$ =
      action$
      |> filter((action) => action.type && action.type.match(/@@redux-form\/(CHANGE)/) && action.meta.form === formName)
    const submit$ =
      formActions$
      |> filter((action) => !excludeFields.includes(action.meta.field))
      |> debounceTime(1000)
      |> map(() => submit(formName))
    return submit$
  }

const talentManagementEpics = [
  fetchEpic(actionTypes.TM.USER_DISPLAY_SETTINGS),
  fetchEpic(actionTypes.TM.NOTIFICATION_TEMPLATES),
  fetchEpic(actionTypes.TM.NOTIFICATIONS.LIST),
  fetchEpic(actionTypes.TM.NOTIFICATIONS.CURRENT),
  fetchEpic(actionTypes.TM.NOTIFICATIONS.ANSWERS),
  fetchEpic(actionTypes.TM.NOTIFICATIONS.ANSWER),
  fetchEpic(actionTypes.TM.NOTIFICATIONS.STATUS),
  fetchEpic(actionTypes.TM.ROLES.CURRENT),
]

const rootEpic = combineEpics(
  fetchEpic(actionTypes.EMPLOYEES.CURRENT),
  fetchEpic(actionTypes.EMPLOYEES.LIST),
  fetchEpic(actionTypes.EMPLOYEES.ALL),
  fetchEpic(actionTypes.EMPLOYEES.DOCUMENT_LIMIT_EXCEEDED),
  fetchEpic(actionTypes.PROCEDURE_STATUSES.LIST),
  fetchEpic(actionTypes.PROCEDURE_STATUSES.CURRENT),
  fetchEpic(actionTypes.PROCEDURE_STATUSES.REPORT),
  fetchEpic(actionTypes.PROCEDURE_STATUSES.TMP_DATA),
  fetchEpic(actionTypes.PROCEDURE_STATUSES.SUMMARY),
  fetchEpic(actionTypes.PROCEDURE_STATUSES.MY_NUMBER_SUMMARY),
  fetchEveryEpic(actionTypes.MASTER),
  fetchEpic(actionTypes.SETTINGS.AUDIT_LOGS.LIST),
  fetchEpic(actionTypes.SETTINGS.AUDIT_LOGS.CURRENT),
  fetchEpic(actionTypes.SETTINGS.ALLOWED_IPS),
  fetchEpic(actionTypes.SETTINGS.ADDITIONAL_NOTIFICATION_DSTS),
  fetchEpic(actionTypes.SETTINGS.LABOR_CONSULTANT),
  fetchEpic(actionTypes.SETTINGS.TALENT_DISPLAY_SETTINGS),
  fetchEpic(actionTypes.SETTINGS.TALENT_SEARCH_SETTINGS),
  fetchEpic(actionTypes.NOTIFICATIONS.LIST),
  fetchEpic(actionTypes.DOCUMENT_EMPLOYEES.LIST),
  ...talentManagementEpics,
  fetchDataEpic,
  mergeErrorEpic,
  search(employeeSearchForm, ['employment_status', 'limit', 'sort_type', 'page']),
  search(documentEmployeeSearchForm, ['employment_status', 'limit', 'sort_type', 'page']),
  search(tmEmployeeSearchForm, ['employment_status', 'limit', 'sort_type', 'page']),
  search(documentEmployeeSelectSearchForm, ['employment_status', 'limit', 'sort_type', 'page']),
  search(auditLogSearchForm, ['operation_type', 'limit', 'page']),
  search(usersClientsSearchForm, ['client_role_id', 'limit', 'sort_type', 'page']),
  search(notificationSearchForm, ['notification_type']),
  search(tmUserSearchForm, ['employment_status', 'limit', 'sort_type', 'page'])
)

export default rootEpic
