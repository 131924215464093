import {createAction} from '@reduxjs/toolkit'
import {notify} from 'reapop'

export const notifySuccess = (message: string) =>
  notify({
    message,
    position: 'top-center',
    status: 'success',
    dismissAfter: 3000,
  })

export const notifyError = createAction<string>('NOTIFY_ERROR')
