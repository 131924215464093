import React from 'react'
import {FormSection} from 'redux-form'
import {Section, RadioField} from 'jbc-front/components/Form'
import {PayrollReportCitySection} from './PayrollReportCitySection'

const EmployeeResidentTax = () => {
  const residentsTaxMethods = [
    {value: 'special', label: '特別徴収', disabled: false, noteWidth: 0, note: null},
    {value: 'normal', label: '普通徴収', disabled: false, noteWidth: 0, note: null},
  ]

  return (
    <Section title="住民税">
      <FormSection name="employee_resident_tax">
        <PayrollReportCitySection />
        <RadioField name="collection_method" label="住民税徴収方法" options={residentsTaxMethods} />
      </FormSection>
    </Section>
  )
}
export default EmployeeResidentTax
