import {FC} from 'react'
import classNames from 'classnames'
import {Glass} from 'jbc-front/components/icons'
import styles from './KeywordSearch.scss'

interface KeywordSearchProps {
  onChange: (value: string) => void
  keyword?: string
  placeholder?: string
  className?: string
}

export const KeywordSearch: FC<KeywordSearchProps> = ({onChange, keyword, placeholder, className}) => {
  return (
    <div className={classNames(styles.keywordSearch, className)}>
      <input
        type="text"
        className={styles.textBox}
        value={keyword}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
      <span className={styles.icon}>
        <Glass size={16} />
      </span>
    </div>
  )
}
