import React, {Component} from 'react'
import {connect} from 'react-redux'
import {ListItem, ListGroup, ListTitle} from 'components/ListGroup'
import {actionCreators} from 'actions'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import api from 'api'
import {toDateStr} from 'utils'
import _ from 'lodash'
import ReportList from 'procedureStatuses/ReportList'
import {Progress} from 'components/Progress'
import DropDownMenu from 'components/DropDownMenu'
import {MenuEllipsis, Clock, Pulldown} from 'jbc-front/components/icons'
import Modal from 'jbc-front/components/Modal'
import {push} from 'connected-react-router'
import styles from 'procedureStatuses/Show.scss'
import Prepare from 'procedureStatuses/eGov/Prepare'
import RelatedRecordsAlertPdf from 'procedureStatuses/RelatedRecordsAlertPdf'
import RelatedRecordsAlertTodo from 'procedureStatuses/RelatedRecordsAlertTodo'
import {MaternityToDoList, MaternityToDoProgress, MaternityMenuItem} from 'procedures/maternityLeave/ToDoList'

const dateNameMap = {
  enroll: '入社予定日',
  resign: '退社予定日',
  add_dependents: '被扶養者異動日',
  remove_dependents: '被扶養者異動日',
  change_remuneration: '',
  change_address: '引越の日',
  change_name: '氏名変更日',
  start_maternity: '出産予定日',
  extend_maternity: '出産予定日',
  finish_maternity: '出産予定日',
}

export const titleMap = {
  enroll: '入社の手続き',
  resign: '退社の手続き',
  add_dependents: '扶養変更の手続き',
  remove_dependents: '扶養変更の手続き',
  change_remuneration: '',
  change_address: '住所変更の手続き',
  change_name: '氏名変更の手続き',
  start_maternity: '産休開始の手続き',
  extend_maternity: '産休変更の手続き',
  finish_maternity: '産休終了の手続き',
}

const toggle = ({onClick}) => (
  <a className={styles.todoMenu} onClick={onClick}>
    <MenuEllipsis size={20} />
  </a>
)

export class Todo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDescriptionOpen: false,
    }
  }

  handleChange = () => {
    return this.props.onTodoChange(this.props.todoListNo, this.props.todoNo, this.props.todo)
  }

  handleDescriptionToggle = () => {
    this.setState({
      isDescriptionOpen: !this.state.isDescriptionOpen,
    })
  }

  render() {
    const {todo} = this.props
    return (
      <div className={styles.todoItem}>
        <div className={styles.todoText}>
          <label className={styles.todoLabel}>
            <input
              type="checkbox"
              onChange={this.handleChange}
              checked={todo.status == 'done'}
              className="m-checkbox-input"
            />
            &nbsp;
            {todo.status === 'done' ? (
              <span className={styles.todoDone + ' m-checkbox-parts'}>{todo.title}</span>
            ) : (
              <span className={styles.todoWill + ' m-checkbox-parts'}>{todo.title}</span>
            )}
          </label>
          {todo.description && this.state.isDescriptionOpen && (
            <div className={styles.todoPulldownShow} onClick={this.handleDescriptionToggle}>
              <Pulldown />
            </div>
          )}
          {todo.description && !this.state.isDescriptionOpen && (
            <div className={styles.todoPulldown} onClick={this.handleDescriptionToggle}>
              <Pulldown />
            </div>
          )}
        </div>
        {todo.due_on && (
          <div className={styles.todoListsDate}>
            <Clock size={13} /> {toDateStr(todo.due_on, 'MMMDo')}
          </div>
        )}
        {todo.description && this.state.isDescriptionOpen && (
          <div className={styles.todoDescription}>{todo.description}</div>
        )}
      </div>
    )
  }
}

class Show extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showDeleteModal: false,
    }
  }

  handleTodoChange = (todoListNo, todoNo, todo) => {
    this.props.updateTodo(this.props.token, todoListNo, todoNo, todo.id, todo.status == 'todo' ? 'done' : 'todo')
  }

  handleTodoChangeAll = (status) => {
    const {updateTodoAll, procedureStatus} = this.props
    updateTodoAll(this.props.token, procedureStatus.id, status)
  }

  handleDelete = () => {
    const {procedureStatus, deleteProcedureStatus, token} = this.props
    deleteProcedureStatus(token, procedureStatus.id)
  }

  handleEditClick = ({num, dependentId}) => {
    const {
      match: {
        params: {id},
      },
    } = this.props
    let editUrl = `/procedure_statuses/${id}/report_edit`
    if (dependentId) {
      editUrl += `?dependent_id=${dependentId}`
    }
    editUrl += `#${_.toUpper(num)}`
    this.props.history.push(editUrl)
  }

  hideDeleteModal = () => {
    this.setState({showDeleteModal: false})
  }

  menuItemsEmptyTodo = [
    {
      onClick: () => {
        this.setState({showDeleteModal: true})
      },
      label: '削除する',
    },
  ]

  menuItems = [
    {
      onClick: () => {
        this.handleTodoChangeAll('done')
      },
      label: '全て完了にする',
    },
    {
      onClick: () => {
        this.handleTodoChangeAll('todo')
      },
      label: '全て対応中に戻す',
    },
    ...this.menuItemsEmptyTodo,
  ]

  isMaternityProcedure = () =>
    ['start_maternity', 'extend_maternity', 'finish_maternity'].some(
      (type) => this.props.procedureStatus?.procedure_type === type
    )

  render() {
    const {
      procedureStatus,
      history,
      match: {
        params: {id},
      },
      loadData,
    } = this.props
    if (_.isEmpty(procedureStatus)) {
      history.push(`/procedure_statuses/`)
      return null
    }
    const {display_last_name, display_first_name} = procedureStatus.employee
    const title = `${display_last_name} ${display_first_name}さんの${titleMap[procedureStatus.procedure_type]}`
    const hasTodoList = procedureStatus.todo_lists && procedureStatus.todo_lists.some((list) => !_.isEmpty(list.todos))

    return (
      <div>
        <div className={styles.headWrap}>
          <h1 className="m-title-main">{title}</h1>
          <div className={styles.subTitle}>
            {dateNameMap[procedureStatus.procedure_type]}: {toDateStr(procedureStatus.date_on)}
          </div>
          <div className={styles.statusWrap}>
            {this.isMaternityProcedure() ? (
              <MaternityToDoProgress procedureStatus={procedureStatus} />
            ) : (
              <>
                <Progress progress={procedureStatus.progress} key="progress" className={styles.progressBar} large />
                進捗状況：{' '}
                <span className={styles.statusCount}>
                  {procedureStatus.done_todos_count}/{procedureStatus.total_todos_count}
                </span>
                {procedureStatus.done_todos_count === procedureStatus.total_todos_count ? (
                  <span className={styles.statusTag}>完了</span>
                ) : (
                  <span className={styles.statusTag}>対応中</span>
                )}
              </>
            )}
          </div>
        </div>

        <div className="l-flex">
          <div className="l-flex4-sub">
            <RelatedRecordsAlertPdf loadProcedureStatus={loadData} />
            <Prepare loadProcedureStatus={loadData} />
            <ReportList procedureStatusId={id} title={title} />
          </div>

          <div className="l-flex4-main">
            <div className={styles.todoHead}>
              <p className={styles.todoTitle}>ToDoリスト</p>
              <div>
                <RelatedRecordsAlertTodo loadProcedureStatus={loadData} />
              </div>
              {this.isMaternityProcedure() ? (
                <MaternityMenuItem toggle={toggle} menuItemsEmptyTodo={this.menuItemsEmptyTodo} />
              ) : (
                <DropDownMenu toggle={toggle} menuItems={hasTodoList ? this.menuItems : this.menuItemsEmptyTodo} />
              )}
            </div>

            {hasTodoList ? (
              this.isMaternityProcedure() ? (
                <MaternityToDoList procedureStatus={procedureStatus} />
              ) : (
                procedureStatus.todo_lists.map(
                  (todoList, todoListNo) =>
                    !_.isEmpty(todoList.todos) && (
                      <ListGroup key={todoListNo}>
                        <ListTitle>
                          <div>
                            {todoList.title}
                            {todoList.due_on && (
                              <span className={styles.todoDate}>
                                <Clock size={16} /> {toDateStr(todoList.due_on, 'MMMDo')}
                              </span>
                            )}
                          </div>
                          {/*<div className={styles.todoMemo}>メモ</div>*/}
                        </ListTitle>
                        {todoList.todos.map((todo, todoNo) => (
                          <ListItem key={todoNo}>
                            <Todo
                              onTodoChange={this.handleTodoChange}
                              todoListNo={todoListNo}
                              todoNo={todoNo}
                              todo={todo}
                            />
                          </ListItem>
                        ))}
                      </ListGroup>
                    )
                )
              )
            ) : (
              <ListGroup>
                <ListItem>ToDoリストがありません</ListItem>
              </ListGroup>
            )}
            <Modal
              header="削除の確認"
              body={`「${display_last_name} ${display_first_name}さんの${
                titleMap[procedureStatus.procedure_type]
              }」を削除しますか`}
              onSubmit={this.handleDelete}
              isOpen={this.state.showDeleteModal}
              hideModal={this.hideDeleteModal}
              submit="はい"
              cancel="いいえ"
            />
          </div>
        </div>
      </div>
    )
  }
}

class Loader extends Component {
  loadData = () => {
    const {id} = this.props.match.params
    const {token, loadData} = this.props
    loadData(id, token)
  }

  state = {
    loading: true,
  }

  componentDidMount() {
    this.loadData()
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  componentDidUpdate(prevProps) {
    if (!this.props.loading && prevProps.loading) {
      const {procedureStatus, history} = this.props
      switch (procedureStatus.status) {
        case 'draft':
          history.replace(`/${procedureStatus.procedure_type}/flow/${this.props.match.params.id}`)
          return
        case 'applying':
          history.replace(`/${procedureStatus.procedure_type}/confirm/${this.props.match.params.id}`)
          return
        default:
          break
      }
      this.setState({loading: false})
    }
  }

  render() {
    return (!this.state.loading && <Show {...this.props} loadData={this.loadData} />) || null
  }
}

const mapStateToProps = (state) => ({
  procedureStatus: state.procedureStatuses.current.data,
  loading: state.procedureStatuses.current.loading || !state.session.currentUser,
  currentUser: state.session.currentUser,
  token: state.auth.token,
})

export default connect(mapStateToProps, (dispatch) => {
  return {
    loadData(id, token) {
      dispatch(
        actionCreators.procedureStatuses.current.fetchData(
          api
            .createWithAuth(token)
            .procedureStatuses.get(
              id,
              [
                'todo_lists',
                'todos',
                'employee',
                'employee_dependents',
                'procedure_related_records',
                'procedure_add_dependents_data',
                'procedure_remove_dependents_data',
                'procedure_change_name_datum',
                'resign_employee_input_datum',
                'procedure_maternity_datum',
                'health_insurance',
                'welfare_pension_insurance',
                'employment_insurance',
                'employee_tax_classification',
                'egov_informations',
                'egov_procedure_statuses',
                'egov_client_information_completed',
                'user',
                'office',
                'exist_r59_param_set',
              ],
              {employee_dependent_removed: [0, 1]}
            )
        )
      )
    },
    destroy() {
      dispatch(actionCreators.procedureStatuses.current.destroy())
    },
    async updateTodo(token, todoListNo, todoNo, todoId, status) {
      try {
        dispatch(actionCreators.procedureStatuses.updateTodo({todoListNo, todoNo, status}))
        await api.createWithAuth(token).todos.update(todoId, status)
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
    async updateTodoAll(token, procedureStatusId, status) {
      try {
        dispatch(actionCreators.procedureStatuses.updateTodoAll({status}))
        await api.createWithAuth(token).procedureStatuses.updateTodoAll(procedureStatusId, status)
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
    async deleteProcedureStatus(token, procedureStatusId) {
      try {
        await api.createWithAuth(token).procedureStatuses.delete(procedureStatusId)
        dispatch(push(`/procedure_statuses`))
        dispatch(notifySuccess('削除しました'))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  }
})(Loader)
