import {useQuery} from 'hooks/graphql'
import {CityByAddressSearchResult, SEARCH_PAYROLL_REPORT_CITY_BY_ADDRESS} from './query'

export const useSearchPayrollReportCityByAddress = (cityName: string | undefined, prefectureId: number | undefined) => {
  const {data} = useQuery<CityByAddressSearchResult>(SEARCH_PAYROLL_REPORT_CITY_BY_ADDRESS, {
    fetchPolicy: 'network-only',
    variables: {
      cityName,
      prefectureId,
    },
  })

  return data?.client?.searchPayrollReportCityByAddress
}
