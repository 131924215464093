import _ from 'lodash'
import {HEALTH_AND_WELFARE_PENSION, EMPLOYMENT, OTHER} from 'procedureStatuses/reportList/common'
import {getRelatedDependents} from 'procedures/changeDependents/utils'

export const getReportList = function* ({
  procedureStatus,
  healthInsuranceType,
  healthInsuranceJoined,
  welfarePensionInsuranceJoined,
  employmentInsuranceJoined,
}) {
  const dependents = getRelatedDependents(_.get(procedureStatus, 'employee.employee_dependents'), procedureStatus)
  const hasDependent = !_.isEmpty(dependents)
  const spouse = dependents.find((dependent) => dependent.relation_type === 'spouse')
  const other_dependents = dependents.filter((dependent) => dependent.relation_type === 'other')
  const employee = procedureStatus.employee
  if (
    (healthInsuranceType === 'kyokai' && (healthInsuranceJoined || welfarePensionInsuranceJoined)) ||
    (healthInsuranceType === 'other' && welfarePensionInsuranceJoined)
  ) {
    yield {
      num: 'r1',
      title: '健康保険・厚生年金保険被保険者資格取得届',
      editable: true,
      type: HEALTH_AND_WELFARE_PENSION,
    }
  }
  if (
    (healthInsuranceType === 'kyokai' && (healthInsuranceJoined || welfarePensionInsuranceJoined)) ||
    (healthInsuranceType === 'other' && healthInsuranceJoined)
  ) {
    if (hasDependent) {
      yield {
        num: 'r4',
        title:
          healthInsuranceType === 'kyokai'
            ? '健康保険被扶養者(異動)･国民年金第３号被保険者関係届'
            : '健康保険被扶養者（異動）届',
        editable: true,
        type: HEALTH_AND_WELFARE_PENSION,
      }
    }
  }
  if (healthInsuranceJoined || welfarePensionInsuranceJoined) {
    if (healthInsuranceType === 'kyokai' || healthInsuranceType === 'other') {
      if (healthInsuranceJoined) {
        if (healthInsuranceType === 'kyokai') {
          yield {
            num: 'r3',
            title: '健康保険被保険者資格証明書交付申請書',
            editable: true,
            type: HEALTH_AND_WELFARE_PENSION,
          }
        }
      }
    } else if (healthInsuranceType === 'its') {
      if (welfarePensionInsuranceJoined) {
        yield {
          num: 'r2',
          title: '健康保険・厚生年金保険被保険者資格取得届',
          editable: true,
          type: HEALTH_AND_WELFARE_PENSION,
        }
      }
      if (healthInsuranceJoined) {
        if (hasDependent) {
          yield {
            num: 'r5',
            title: '被扶養者（異動）届',
            editable: true,
            type: HEALTH_AND_WELFARE_PENSION,
          }
          if (spouse) {
            yield {
              num: 'r150',
              title: '被扶養者現況表（配偶者）',
              editable: true,
              dependentId: spouse.id,
              type: HEALTH_AND_WELFARE_PENSION,
            }
          }
          yield* other_dependents.map((dependent) => ({
            num: 'r150',
            title: `被扶養者現況表（${dependent.relation_other}）`,
            editable: true,
            dependentId: dependent.id,
            type: HEALTH_AND_WELFARE_PENSION,
          }))
        }
      }
    }
  }
  if (welfarePensionInsuranceJoined) {
    if (spouse && healthInsuranceType !== 'kyokai') {
      yield {
        num: 'r6',
        title: '国民年金第３号被保険者関係届',
        editable: true,
        type: HEALTH_AND_WELFARE_PENSION,
      }
    }
    if (employee.national_type === 'foreigner') {
      yield {
        num: 'r55',
        title: '厚生年金被保険者 ローマ字氏名届',
        editable: true,
        type: HEALTH_AND_WELFARE_PENSION,
      }
    }
    if (spouse && spouse.national_type === 'foreigner') {
      yield {
        num: 'r56',
        title: '国民年金第３号被保険者ローマ字氏名届',
        editable: true,
        type: HEALTH_AND_WELFARE_PENSION,
      }
    }
  }

  if (employmentInsuranceJoined) {
    yield {
      num: 'r57',
      title: '雇用保険被保険者資格取得届',
      editable: true,
      type: EMPLOYMENT,
    }
  } else if (employee.national_type === 'foreigner') {
    yield {
      num: 'r71',
      title: '外国人雇用状況届出書',
      editable: true,
      type: OTHER,
    }
  }

  if (employee.employee_tax_classification?.tax_table_type === 'main_income') {
    yield {
      num: 'r132',
      title: '給与所得者の扶養控除等（異動）申告書',
      editable: false,
      type: OTHER,
    }
  }
}

const ProcedureInfo = () => null

export default {getReportList, ProcedureInfo}
