import React, {Component} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'
import NaviForm from 'employees/NaviForm'
import {withFormSelectors, withFormSelectorsProvider} from 'employees/form/common'
import {push} from 'connected-react-router'
import {actionCreators} from 'actions'
import api from 'api'
import {loadEmployee} from 'employees/Show'
import Button from 'jbc-front/components/Button'
import {handlerNestFormResponse} from 'libs/errorHandler'
import updateCustomValues from 'employees/updateCustomValues'
import {fetchSelector} from 'actions'
import {parseBackUrl} from 'utils'
import {parse} from 'query-string'
import {Consumer as AsyncTaskConsumer} from 'AsyncTask'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

export const updateEmployeeDetail = async (id, values, token, employeeFiles, customFields, opt = {}) => {
  const {
    employee,
    residentCard,
    emergencyContact,
    welfarePensionInsurance,
    employmentInsurance,
    healthInsurance,
    dependents,
    employeeTaxClassification,
    commutingExpenses,
    leaveOfAbsences,
    contractPeriods,
    resumes,
    customValues,
    visaHistory,
    employeeDependentsInSingle,
    employeeContactInformation,
    employeeProjects,
    employeeSkills,
    employeeQualifications,
    employeeLanguages,
    employeeWorkHistories,
    employeeEducationBackgrounds,
    employeeTags,
    employeeResidentTax,
  } = values
  const authedApi = api.createWithAuth(token)
  if (employee) {
    await authedApi.employees.update(id, employee, employeeFiles)
  }
  const result = {}
  if (residentCard) {
    if (residentCard.same_address) {
      if (residentCard.id) {
        await authedApi.employees.residentCard.delete(id, residentCard)
      }
    } else {
      await authedApi.employees.residentCard.create(id, residentCard).catch(handlerNestFormResponse('resident_card'))
    }
  }
  if (emergencyContact) {
    await authedApi.employees.emergencyContact
      .create(id, emergencyContact)
      .catch(handlerNestFormResponse('emergency_contact'))
  }
  if (welfarePensionInsurance) {
    await authedApi.employees.welfarePensionInsurance
      .create(id, welfarePensionInsurance)
      .catch(handlerNestFormResponse('welfare_pension_insurance'))
  }
  if (employmentInsurance) {
    await authedApi.employees.employmentInsurance
      .create(id, employmentInsurance)
      .catch(handlerNestFormResponse('employment_insurance'))
  }
  if (healthInsurance) {
    await authedApi.employees.healthInsurance
      .create(id, healthInsurance)
      .catch(handlerNestFormResponse('health_insurance'))
  }
  if (dependents) {
    const {data} = await authedApi.employees.dependents
      .updateAll(id, dependents, !!opt['dependentsUpdateOnly'])
      .catch(handlerNestFormResponse('dependents'))
    result.dependents = data
  }
  if (employeeTaxClassification) {
    await authedApi.employees.employeeTaxClassification
      .update(id, employeeTaxClassification)
      .catch(handlerNestFormResponse('employee_tax_classification'))
  }
  if (commutingExpenses) {
    await authedApi.employees.commutingExpenses
      .updateAll(id, commutingExpenses)
      .catch(handlerNestFormResponse('commuting_expenses'))
  }
  if (leaveOfAbsences) {
    await authedApi.employees.leaveOfAbsences
      .updateAll(id, leaveOfAbsences)
      .catch(handlerNestFormResponse('leave_of_absences'))
  }
  if (contractPeriods) {
    await authedApi.employees.contractPeriods
      .updateAll(id, contractPeriods)
      .catch(handlerNestFormResponse('contract_periods'))
  }
  if (resumes) {
    const files = resumes.file || []
    const {data: resumesDb} = await authedApi.employees.resumes.list(id)
    const resumeIds = files.map((file) => file.id).filter((id) => id)
    for (let {id: resumeId} of resumesDb) {
      if (!resumeIds.includes(resumeId)) {
        await authedApi.employees.resumes.delete(id, resumeId)
      }
    }
    let index = 0
    for (let file of files) {
      if (!file.id) {
        await authedApi.employees.resumes.create(id, file).catch(handlerNestFormResponse(`resumes[${index}]`))
      }
      index++
    }
  }
  if (customValues && customFields) {
    await updateCustomValues(id, customValues, customFields, token)
  }
  if (visaHistory && employee.national_type === 'foreigner') {
    await authedApi.employees.visaHistories
      .updateAll(id, {visa_histories: [visaHistory]}, true)
      .catch(handlerNestFormResponse('visa_history'))
  }
  if (employeeDependentsInSingle) {
    if (employeeDependentsInSingle.dependents_in_single_type === 'none') {
      await authedApi.employees.dependentsInSingles.delete(id).catch(handlerNestFormResponse('dependents_in_single'))
    } else {
      await authedApi.employees.dependentsInSingles
        .update(id, employeeDependentsInSingle)
        .catch(handlerNestFormResponse('dependents_in_single'))
    }
  }
  if (employeeContactInformation) {
    await authedApi.employees.employeeContactInformation
      .update(id, employeeContactInformation)
      .catch(handlerNestFormResponse('employee_contact_information'))
  }
  if (employeeProjects) {
    await authedApi.employees.employeeProjects
      .updateAll(id, employeeProjects)
      .catch(handlerNestFormResponse('employee_projects'))
  }
  if (employeeSkills) {
    await authedApi.employees.employeeSkills
      .updateAll(id, employeeSkills)
      .catch(handlerNestFormResponse('employee_skills'))
  }
  if (employeeQualifications) {
    await authedApi.employees.employeeQualifications
      .updateAll(id, employeeQualifications, true)
      .catch(handlerNestFormResponse('employee_qualifications'))
  }
  if (employeeLanguages) {
    await authedApi.employees.employeeLanguages
      .updateAll(id, employeeLanguages, true)
      .catch(handlerNestFormResponse('employee_languages'))
  }
  if (employeeWorkHistories) {
    await authedApi.employees.employeeWorkHistories
      .updateAll(id, employeeWorkHistories)
      .catch(handlerNestFormResponse('employee_work_histories'))
  }
  if (employeeEducationBackgrounds) {
    await authedApi.employees.employeeEducationBackgrounds
      .updateAll(id, employeeEducationBackgrounds)
      .catch(handlerNestFormResponse('employee_education_backgrounds'))
  }
  if (employeeTags) {
    await authedApi.employees.employeeTags.updateAll(id, employeeTags).catch(handlerNestFormResponse('employee_tags'))
  }
  if (employeeResidentTax) {
    await authedApi.employees.employeeResidentTax
      .update(id, employeeResidentTax)
      .catch(handlerNestFormResponse('employee_resident_tax'))
  }
  return result
}

class Update extends Component {
  componentDidMount() {
    const {id} = this.props.match.params
    const {token, client} = this.props
    this.props.loadData(id, token)
    this.props.loadFieldGroup(token, client)
  }

  handleSubmit = (values) => {
    const {location, updateEmployee, token, fields} = this.props
    return updateEmployee(values, token, parseBackUrl(location), fields)
  }

  handleSubmitFromProcedure = (values) => {
    const {
      location: {search},
      updateEmployee,
      token,
      fields,
    } = this.props
    const url =
      parse(search).next_action === 'confirm'
        ? `/${parse(search).procedure_type}/confirm/${parse(search).procedure_status_id}`
        : `/${parse(search).procedure_type}/${parse(search).next_action}?employee_id=${values.employee.id}`
    return updateEmployee(values, token, url, fields)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  componentDidUpdate(prevProps) {
    const {
      fields,
      location: {hash},
    } = this.props
    const name = hash.slice(1)
    if (name && prevProps.fields !== fields) {
      const {current} = this._form.state
      if (name !== current) {
        this._form.scrollByHash()
      }
    }
  }

  render() {
    const {
      data,
      fieldGroups,
      employeeFieldGroups,
      submit,
      submitting,
      pristine,
      location: {search},
      selector,
    } = this.props

    if (!parse(search).procedure_type) {
      return (
        <NaviForm
          data={data}
          fieldGroups={fieldGroups}
          employeeFieldGroups={employeeFieldGroups}
          onSubmit={this.handleSubmit}
          needDirty={true}
          submitText="更新"
          ref={(form) => (this._form = form)}
          employee={data}
          naviSubmit={
            <AsyncTaskConsumer>
              {({taskRunningProps}) => (
                <Button primary disabled={submitting || pristine} onClick={submit} widthWide {...taskRunningProps}>
                  更新
                </Button>
              )}
            </AsyncTaskConsumer>
          }
          selector={selector}
          isProccesing={_.get(data, 'detail_input_request.status') === 'applying'}
        />
      )
    } else {
      return (
        <NaviForm
          data={data}
          fieldGroups={fieldGroups}
          employeeFieldGroups={employeeFieldGroups}
          onSubmit={this.handleSubmitFromProcedure}
          needDirty={true}
          submitText="次へ"
          withAlert="従業員必須情報が入力されていません。手続きを行うために従業員必須情報を入力してください。"
          ref={(form) => (this._form = form)}
          employee={data}
          naviSubmit={
            <AsyncTaskConsumer>
              {({taskRunningProps}) => (
                <Button primary disabled={submitting || pristine} onClick={submit} widthWide {...taskRunningProps}>
                  次へ
                </Button>
              )}
            </AsyncTaskConsumer>
          }
          selector={selector}
        />
      )
    }
  }
}

export default Update
  |> connect(
    (state, {isSubmitting, isPristine}) => ({
      data: state.employees.current.data,
      submitting: isSubmitting(state),
      pristine: isPristine(state),
      loading: state.employees.current.loading,
      token: state.auth.token,
      client: state.client.current,
      fields: fetchSelector(state, 'custom_fields').data,
      fieldGroups: fetchSelector(state, 'custom_field_groups').data,
      employeeFieldGroups: fetchSelector(state, 'employee_field_groups').data,
      location: state.router.location,
    }),
    (dispatch, {submit}) => ({
      loadData(id, token) {
        dispatch(actionCreators.employees.current.fetchData(loadEmployee(id, token)))
      },
      loadFieldGroup(token, client) {
        dispatch(
          actionCreators.fetchData('employee_field_groups', api.createWithAuth(token).employeeFieldGroups.list())
        )
        dispatch(
          actionCreators.fetchData(
            'custom_field_groups',
            api.createWithAuth(token).employeeCustom.fieldGroups.list(client.id)
          )
        )
        dispatch(
          actionCreators.fetchData(
            'custom_fields',
            api.createWithAuth(token).employeeCustom.fields.list(client.id, {
              embed: ['custom_employee_field_options', 'custom_employee_field_permissions'],
            })
          )
        )
      },
      destroy() {
        dispatch(actionCreators.employees.current.destroy())
        dispatch(actionCreators.fetchDestroy('employee_field_groups'))
        dispatch(actionCreators.fetchDestroy('custom_field_groups'))
        dispatch(actionCreators.fetchDestroy('custom_fields'))
      },
      async updateEmployee(values, token, backTo, fields) {
        try {
          const employeeId = values.employee.id
          await updateEmployeeDetail(employeeId, values, token, undefined, fields)
          if (backTo) {
            dispatch(push(backTo))
          } else {
            dispatch(push(`/employees/${employeeId}`))
          }
          dispatch(notifySuccess('更新しました'))
        } catch (err) {
          dispatch(asyncError(err))
        }
      },
      submit: () => {
        dispatch(submit)
      },
    })
  )
  |> withFormSelectors
  |> withFormSelectorsProvider
