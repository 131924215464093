import React, {useState, useRef, useContext, useEffect} from 'react'
import {reduxForm} from 'redux-form'
import _ from 'lodash'
import Button from 'jbc-front/components/Button'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import scrollSpy from 'jbc-front/components/ScrollSpy'

import {
  FieldGroupStore,
  BasicInfomation,
  Dependents,
  CurriculumVitae,
  EmploymentInsuranceInfomation,
  BankInfomation,
  PersonalType,
  WelfarePensionInsuranceInfomation,
  CommutingAllowanceInfomation,
  EmergencyContact,
  ResidentCard,
  EmployeeResidentTax,
  Marital,
  EmployeeContactInformation,
  EmployeeProject,
  EmployeeSkill,
  EmployeeQualification,
  EmployeeLanguage,
  EmployeeWorkHistory,
  EmployeeEducationBackground,
  EmployeeTag,
} from 'employees/FieldWithGroup'

import Master from 'Master'
import {validate as employmentInsuranceValidate} from 'employees/form/EmploymentInsuranceInfomation'
import CustomFields from 'employees/form/CustomFields'
import {connect} from 'react-redux'
import {fetchSelector} from 'actions'
import {useInitialValues} from 'employees/form/common'
import ReviewComments from 'components/ReviewComments'
import CancelModal from 'accountSettings/CancelModal'
import {sortFieldGroupsAll} from 'utils'
import styles from 'accountSettings/ProfileForm.scss'

export const formName = 'profileForm'

const validate = (values) => {
  const errors = Object.assign({}, ...[employmentInsuranceValidate].map((validate) => validate(values)))
  return _.isEmpty(errors) ? undefined : errors
}

const HoverNote = ({message, children}) => {
  const [isHover, setHoverState] = useState(false)
  const container = useRef(null)
  const shapeWidth = 15
  const childWidth = _.get(container, 'current.clientWidth')
  return (
    <div
      className={styles.note}
      onMouseOver={() => setHoverState(true)}
      onMouseOut={() => setHoverState(false)}
      ref={container}
    >
      {isHover && (
        <>
          <div className={styles.shape} style={{left: childWidth ? `${childWidth / 2 - shapeWidth / 2}px` : '0'}} />
          <div className={styles.message}>{message}</div>
        </>
      )}
      {children}
    </div>
  )
}

const canSendRequest = (requestStatus) => !['none', 'applying'].includes(requestStatus)

const SubTitle = ({requestStatus, fields}) => {
  const {groups} = useContext(FieldGroupStore)
  const updatableFields = _.filter(fields, (field) => field.permission === 'updatable')
  const isEmptyFields = _.isEmpty(groups) && _.isEmpty(updatableFields)
  if (isEmptyFields) {
    return <p>管理者の設定により編集することができません。</p>
  }
  if (!canSendRequest(requestStatus)) {
    return <p>情報更新が完了するまで新たに更新の依頼を送ることは出来ません。</p>
  }
  if (requestStatus === 'rejected') {
    return null
  }
  return <p>更新依頼したい情報がある場合は、その情報を編集してください</p>
}

const FormComponent = ({
  handleSubmit,
  pristine,
  submitting,
  submitText,
  data,
  procedureType,
  healthInsuranceType,
  comments,
  requestStatus,
  fields,
  fieldGroups,
  employeeFieldGroups,
}) => {
  const fieldGroupsAll = employeeFieldGroups && fieldGroups && sortFieldGroupsAll(employeeFieldGroups, fieldGroups)

  return (
    !_.isEmpty(data) && (
      <form onSubmit={handleSubmit}>
        <Master masters={['nations', 'languages', 'languageLevels', 'schoolTypes']} />
        <div className="l-title-wrap">
          <h1 className="m-title-main">プロフィール編集</h1>
          <SubTitle requestStatus={requestStatus} fields={fields} />
        </div>
        {requestStatus === 'rejected' && <ReviewComments comments={comments} />}
        <div className={styles.profileFormInformation}>
          <FormErrors />
          {fieldGroupsAll &&
            fieldGroupsAll.map((group, index) => {
              switch (group?.code) {
                case 'basic_infomation':
                  return (
                    <BasicInfomation
                      key={index}
                      name="BasicInfomation"
                      employee={data}
                      procedureType={procedureType}
                      isForceEmployee
                    />
                  )
                case 'curriculum_vitae':
                  return <CurriculumVitae key={index} name="CurriculumVitae" />
                case 'commuting_allowance_infomation':
                  return <CommutingAllowanceInfomation key={index} name="CommutingAllowanceInfomation" />
                case 'bank_infomation':
                  return <BankInfomation key={index} name="BankInfomation" formName={formName} />
                case 'dependent':
                  return <Dependents key={index} name="Dependent" procedureType={procedureType} isForceEmployee />
                case 'marital':
                  return <Marital key={index} name="Marital" isForceEmployee />
                case 'emergency_contact':
                  return <EmergencyContact key={index} name="EmergencyContact" />
                case 'resident_card':
                  return (
                    <ResidentCard
                      key={index}
                      name="ResidentCard"
                      procedureType={procedureType}
                      employee={data}
                      isForceEmployee
                    />
                  )
                case 'employee_resident_tax':
                  return <EmployeeResidentTax key={index} name="EmployeeResidentTax" isForceEmployee />
                case 'personal_type':
                  return <PersonalType key={index} name="PersonalType" />
                case 'welfare_pension_insurance_infomation':
                  return (
                    <WelfarePensionInsuranceInfomation
                      key={index}
                      name="WelfarePensionInsuranceInfomation"
                      healthInsuranceType={healthInsuranceType}
                      isForceEmployee
                    />
                  )
                case 'employment_insurance_infomation':
                  return (
                    <EmploymentInsuranceInfomation key={index} name="EmploymentInsuranceInfomation" isForceEmployee />
                  )
                case 'employee_contact_information':
                  return <EmployeeContactInformation key={index} name="EmployeeContactInformation" />
                case 'employee_project':
                  return <EmployeeProject key={index} name="EmployeeProject" />
                case 'employee_skill':
                  return <EmployeeSkill key={index} name="EmployeeSkill" />
                case 'employee_qualification':
                  return <EmployeeQualification key={index} name="EmployeeQualification" />
                case 'employee_language':
                  return <EmployeeLanguage key={index} name="EmployeeLanguage" />
                case 'employee_work_history':
                  return <EmployeeWorkHistory key={index} name="EmployeeWorkHistory" />
                case 'employee_education_background':
                  return <EmployeeEducationBackground key={index} name="EmployeeEducationBackground" />
                case 'employee_tag':
                  return <EmployeeTag key={index} name="EmployeeTag" />
                case undefined:
                  return group.key === 'custom_employee_field_group' ? (
                    <CustomFields key={index} group={group} addAnchorTarget />
                  ) : null
                default:
                  return null
              }
            })}
          <div className="u-ta-c u-mt30">
            {requestStatus === 'rejected' && (
              <CancelModal
                id={_.get(data, 'id')}
                render={({showModal}) => (
                  <Button onClick={showModal} className="u-mr20">
                    依頼を取り下げる
                  </Button>
                )}
              />
            )}
            {!canSendRequest(requestStatus) ? (
              <HoverNote message="情報更新が完了するまで新たに更新の依頼を送ることは出来ません">
                <Button primary onClick={handleSubmit} disabled>
                  {requestStatus === 'rejected' ? '修正完了' : submitText}
                </Button>
              </HoverNote>
            ) : (
              <Button primary onClick={handleSubmit} disabled={pristine || submitting}>
                {requestStatus === 'rejected' ? '修正完了' : submitText}
              </Button>
            )}
          </div>
        </div>
      </form>
    )
  )
}

const Form =
  FormComponent
  |> reduxForm({
    form: formName,
    enableReinitialize: true,
    onSubmitFail,
    validate,
  })
  |> scrollSpy

const FormInitialized = ({
  data,
  onSubmit,
  fields,
  fieldGroups,
  employeeFieldGroups,
  nations,
  tmpData,
  tmpFiles,
  isRejected,
  location: {hash},
  ...rest
}) => {
  const initialValues = useInitialValues({data, nations, tmpData, tmpFiles})
  const healthInsuranceType = _.get(data, 'office.health_insurance_type')
  const comments = _.get(data, 'detail_input_request.comments')
  const formRef = useRef({scrollByHash: () => null})
  useEffect(() => {
    const name = hash.slice(1)
    const ref = formRef.current || {}
    const current = ref?.state?.current
    if (name !== current) {
      ref.scrollByHash()
    }
  }, [fields])

  return (
    <Form
      {...{
        initialValues,
        data,
        healthInsuranceType,
        onSubmit,
        fields,
        fieldGroups,
        employeeFieldGroups,
        isRejected,
        comments,
        ref: formRef,
        location,
        ...rest,
      }}
    />
  )
}

export default FormInitialized
  |> connect((state) => ({
    fields: fetchSelector(state, 'custom_fields').data,
    location: state.router.location,
  }))
