import {FC} from 'react'
import {Link} from 'react-router-dom'
import useReactRouter from 'use-react-router'
import {Header} from 'components/layout/Header'
import styles from './Header.scss'
import {Logo} from './parts/Logo'
import {Help} from './parts/Help'
import {NotVerified} from './parts/NotVerified'
import {UserMenu, MenuItem} from './parts/UserMenu'

import type {User} from 'types/api/users/user'
import type {CurrentClientForAdmin, CurrentClientForMember} from 'types/api/clients/currentClient'

interface MypageLoggedInHeaderProps {
  client?: CurrentClientForAdmin | CurrentClientForMember
  currentUser: User | Record<string, never>
}

export const MypageLoggedInHeader: FC<MypageLoggedInHeaderProps> = ({client, currentUser = {}}) => {
  const {history} = useReactRouter()
  const name = currentUser.employee?.display_full_name || currentUser.email
  const clientRole = currentUser.client_role?.client_role_type
  const customerCode = currentUser.customer_code
  const isVerified = client && 'is_verified' in client && !client.is_verified

  const menuItems: MenuItem[] = []
  if (clientRole !== 'member') {
    menuItems.push({
      label: '問い合わせ番号',
      description: customerCode,
      style: styles.customerCodeStyle,
    })
  }
  if (!currentUser.employee) {
    menuItems.push(
      {label: 'アカウント設定', onClick: () => history.push('/account_settings/account')},
      {label: 'ログアウト', onClick: () => history.push('/logout')}
    )
  }

  return (
    <Header.Content>
      <Link to="/dashboard">
        <Logo />
      </Link>
      {clientRole !== 'member' && isVerified && <NotVerified />}
      <Help />
      <UserMenu name={name} menuItems={menuItems} />
    </Header.Content>
  )
}
