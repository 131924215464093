import {useQuery} from 'hooks/graphql'
import {CitiesSearchResult, SEARCH_PAYROLL_REPORT_CITIES} from './query'

export const useSearchPayrollReportCity = (
  page: number,
  perPage: number,
  searchKeyword: string | undefined,
  skip: boolean
) => {
  const {data, loading} = useQuery<CitiesSearchResult>(SEARCH_PAYROLL_REPORT_CITIES, {
    fetchPolicy: 'network-only',
    variables: {per: perPage, page, search: searchKeyword},
    skip: skip,
  })

  const payrollReportCities = data?.client?.searchPayrollReportCities?.list
  const totalCount = data?.client?.searchPayrollReportCities?.totalCount

  return {payrollReportCities, totalCount, loading}
}
