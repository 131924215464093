import React from 'react'
import {Panel, Item} from 'components/PrimaryPanel'
import {recordDisplay} from 'utils'
import {connect} from 'react-redux'
import {Grid, Dl, Dt, Dd} from 'employees/show/Parts'
import {HeaddingWithLink} from 'employees/show/utils'

const RESIDENTS_TAX_COLLECTION_METHODS = {
  special: '特別徴収',
  normal: '普通徴収',
}

const EmployeeResidentTax = ({employee, editLink, canEdit}) => (
  <Panel>
    <HeaddingWithLink to={`${editLink}#EmployeeResidentTax`} title="住民税" canEdit={canEdit} />
    <Item>
      <Grid>
        <Dl>
          <Dt>給与支払報告書提出先 市区町村コード</Dt>
          <Dd>{recordDisplay(employee.employee_resident_tax?.city_code)}</Dd>
        </Dl>

        <Dl>
          <Dt>給与支払報告書提出先 市区町村</Dt>
          <Dd>{recordDisplay(employee.employee_resident_tax?.city_name)}</Dd>
        </Dl>

        <Dl>
          <Dt>住民税徴収方法</Dt>
          <Dd>{recordDisplay(RESIDENTS_TAX_COLLECTION_METHODS[employee.employee_resident_tax?.collection_method])}</Dd>
        </Dl>
      </Grid>
    </Item>
  </Panel>
)

export default connect((state) => ({
  employee: state.employees.current.data,
}))(EmployeeResidentTax)
