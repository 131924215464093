import React, {useContext} from 'react'
import {reduxForm} from 'redux-form'
import {connect} from 'react-redux'
import _ from 'lodash'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import Button from 'jbc-front/components/Button'
import {useInitialValues} from 'employees/form/common'
import Master from 'Master'
import {hasVisaHistory} from 'employees/form/BasicInfomation'
import {validate as employmentValidate} from 'employees/form/EmploymentInfomation'
import {validate as dependentsValidate} from 'employees/form/Dependents'
import {validate as projectsValidate} from 'employees/form/EmployeeProject'
import {validate as workHistoriesValidate} from 'employees/form/EmployeeWorkHistory'
import {validate as educationBackgroundsValidate} from 'employees/form/EmployeeEducationBackground'
import {validate as employmentInsuranceValidate} from 'employees/form/EmploymentInsuranceInfomation'
import {trainTypes, carTypes, busTypes} from 'employees/form/CommutingAllowanceInfomation'
import CustomFields from 'employees/form/CustomFields'
import {fetchSelector} from 'actions'
import {Consumer as AsyncTaskConsumer} from 'AsyncTask'
import CancelModal from 'accountSettings/CancelModal'
import {isAdminSelector, sortFieldGroupsAll} from 'utils'
import {formatValues} from 'employees/utils'
import classNames from 'classnames'
import styles from './Form.scss'
import {
  FieldGroupStore,
  BasicInfomation,
  EmploymentInfomation,
  Dependents,
  EmploymentInsuranceInfomation,
  RemunerationInfomation,
  BankInfomation,
  PersonalType,
  WelfarePensionInsuranceInfomation,
  HealthInsuranceInfomation,
  CommutingAllowanceInfomation,
  EmergencyContact,
  ResidentCard,
  EmployeeResidentTax,
  Marital,
  CurriculumVitae,
  EmployeeContactInformation,
  EmployeeProject,
  EmployeeSkill,
  EmployeeQualification,
  EmployeeLanguage,
  EmployeeWorkHistory,
  EmployeeEducationBackground,
  EmployeeTag,
} from 'employees/FieldWithGroup'

const formName = 'employeeForm'

const masters = (hideEmploymentInfo) =>
  hideEmploymentInfo
    ? ['visaTypes', 'nations', 'languages', 'languageLevels', 'schoolTypes']
    : ['visaTypes', 'nations', 'languages', 'languageLevels', 'schoolTypes', 'standardMonthlyAmounts']

const validate = (values) => {
  const errors = Object.assign(
    {},
    ...[
      dependentsValidate,
      employmentValidate,
      projectsValidate,
      workHistoriesValidate,
      educationBackgroundsValidate,
      employmentInsuranceValidate,
    ].map((validate) => validate(values))
  )
  return _.isEmpty(errors) ? undefined : errors
}

export const makeFormValues = ({values, hideEmploymentInfo, procedureType}) => {
  const employee = _.omit(formatValues(values), [
    'resident_card',
    'emergency_contact',
    'welfare_pension_insurance',
    'employee_tax_classification',
    'employment_insurance',
    'health_insurance',
    'dependents',
    'personnel_history',
    'visa_history',
    'commuting_expenses',
    'employee_leave_of_absences',
    'contract_periods',
    'resumes',
    'custom_fields',
    'employee_dependents_in_single',
    'employee_contact_information',
    'employee_projects',
    'employee_skills',
    'employee_qualifications',
    'employee_languages',
    'employee_work_histories',
    'employee_education_backgrounds',
    'employee_tags',
    'employee_resident_tax',
  ])
  const residentCard = !_.isEmpty(values.resident_card) ? values.resident_card : null
  const emergencyContact = !_.isEmpty(values.emergency_contact) ? values.emergency_contact : null
  const employeeTaxClassification = !_.isEmpty(values.employee_tax_classification)
    ? values.employee_tax_classification
    : null
  const healthInsurance = !_.isEmpty(values.health_insurance)
    ? values.health_insurance.joined
      ? values.health_insurance
      : {joined: false}
    : null
  const welfarePensionInsurance = !_.isEmpty(values.welfare_pension_insurance)
    ? values.welfare_pension_insurance.joined
      ? values.welfare_pension_insurance
      : {joined: false}
    : null
  const employmentInsurance = !_.isEmpty(values.employment_insurance)
    ? values.employment_insurance.joined
      ? values.employment_insurance
      : {joined: false}
    : null
  const dependents = values.has_dependent && values.dependents && values.dependents.length > 0 ? values.dependents : []
  const commutingExpenses = getCommutingExpenseValues(values.commute_type, values.commuting_expenses)
  const leaveOfAbsences = hideEmploymentInfo ? null : values.employee_leave_of_absences
  const employeeResidentTax = values.employee_resident_tax
  const resumes = values.resumes
  const contractPeriods =
    !hideEmploymentInfo && values.has_contract_period && !_.isEmpty(values.contract_periods)
      ? values.contract_periods
      : null
  const customFields = values.custom_fields || {}
  const customValues = _.map(customFields, (field, key) => {
    const id = key.match(/field_(.*)/)[1]
    return {custom_employee_field_id: id, value: field}
  })
  if (procedureType) {
    employee._procedure_type = procedureType
  }
  if (!employee.use_business_name) {
    employee.business_last_name = null
    employee.business_first_name = null
  }
  const visaHistory = values._no_visa_history && hasVisaHistory(values.visa_history) ? values.visa_history : null

  const employeeDependentsInSingle = _.merge(
    {dependents_in_single_type: values.dependents_in_single_type},
    values.employee_dependents_in_single
  )

  const employeeContactInformation = !_.isEmpty(values.employee_contact_information)
    ? values.employee_contact_information
    : null

  const employeeProjects = values.employee_projects
  const employeeSkills = values.employee_skills
  const employeeQualifications = values.employee_qualifications
  const employeeLanguages = values.employee_languages
  const employeeWorkHistories = values.employee_work_histories
  const employeeEducationBackgrounds = values.employee_education_backgrounds
  const employeeTags = values.employee_tags

  return {
    employee,
    residentCard,
    employeeResidentTax,
    emergencyContact,
    healthInsurance,
    employeeTaxClassification,
    welfarePensionInsurance,
    employmentInsurance,
    dependents,
    commutingExpenses,
    leaveOfAbsences,
    resumes,
    contractPeriods,
    customValues,
    visaHistory,
    employeeDependentsInSingle,
    employeeContactInformation,
    employeeProjects,
    employeeSkills,
    employeeQualifications,
    employeeLanguages,
    employeeWorkHistories,
    employeeEducationBackgrounds,
    employeeTags,
  }
}

const EmployeeForm = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
  validate,
})(
  ({
    data,
    fieldGroupsAll,
    handleSubmit,
    pristine,
    submitting,
    hideEmploymentInfo,
    submitText,
    needDirty,
    procedureType,
    otherButtons,
    healthInsuranceType,
    showHealthInsurance,
    isInitialInput,
    isForceEmployee,
    shouldShowCancel,
    employee,
  }) => (
    <form onSubmit={handleSubmit}>
      <div
        className={classNames('basic_information', !employee ? styles.notHaveJobcanHeader : styles.yesHaveJobcanHeader)}
      >
        <FormErrors />
        {fieldGroupsAll &&
          fieldGroupsAll.map((group, index) => {
            switch (group.code) {
              case 'basic_infomation':
                return (
                  <BasicInfomation
                    key={index}
                    name="BasicInfomation"
                    isInitialInput={isInitialInput}
                    isForceEmployee={isForceEmployee}
                    employee={data}
                  />
                )
              case 'employment_infomation':
                return (
                  !hideEmploymentInfo && (
                    <EmploymentInfomation key={index} name="EmploymentInfomation" procedureType={procedureType} />
                  )
                )
              case 'curriculum_vitae':
                return <CurriculumVitae key={index} name="CurriculumVitae" />
              case 'remuneration_infomation':
                return !hideEmploymentInfo && <RemunerationInfomation key={index} name="RemunerationInfomation" />
              case 'commuting_allowance_infomation':
                return <CommutingAllowanceInfomation key={index} name="CommutingAllowanceInfomation" />
              case 'bank_infomation':
                return <BankInfomation key={index} name="BankInfomation" formName={formName} />
              case 'dependent':
                return <Dependents key={index} name="Dependent" {...{procedureType, isInitialInput, isForceEmployee}} />
              case 'marital':
                return <Marital key={index} name="Marital" isForceEmployee={isForceEmployee} />
              case 'emergency_contact':
                return <EmergencyContact key={index} name="EmergencyContact" />
              case 'resident_card':
                return (
                  <ResidentCard
                    key={index}
                    name="ResidentCard"
                    isInitialInput={isInitialInput}
                    isForceEmployee={isForceEmployee}
                  />
                )
              case 'employee_resident_tax':
                return (
                  <EmployeeResidentTax
                    key={index}
                    name="EmployeeResidentTax"
                    isInitialInput={isInitialInput}
                    isForceEmployee={isForceEmployee}
                  />
                )
              case 'personal_type':
                return <PersonalType key={index} name="PersonalType" />
              case 'health_insurance_infomation':
                return (
                  showHealthInsurance && (
                    <HealthInsuranceInfomation
                      key={index}
                      name="HealthInsuranceInfomation"
                      procedureType={procedureType}
                    />
                  )
                )
              case 'welfare_pension_insurance_infomation':
                return (
                  <WelfarePensionInsuranceInfomation
                    key={index}
                    name="WelfarePensionInsuranceInfomation"
                    {...{
                      procedureType,
                      healthInsuranceType,
                      isInitialInput,
                      isForceEmployee,
                    }}
                  />
                )
              case 'employment_insurance_infomation':
                return (
                  <EmploymentInsuranceInfomation
                    key={index}
                    name="EmploymentInsuranceInfomation"
                    {...{
                      procedureType,
                      isInitialInput,
                      isForceEmployee,
                    }}
                  />
                )
              case 'employee_contact_information':
                return <EmployeeContactInformation key={index} name="EmployeeContactInformation" />
              case 'employee_project':
                return <EmployeeProject key={index} name="EmployeeProject" />
              case 'employee_skill':
                return <EmployeeSkill key={index} name="EmployeeSkill" />
              case 'employee_qualification':
                return <EmployeeQualification key={index} name="EmployeeQualification" />
              case 'employee_language':
                return <EmployeeLanguage key={index} name="EmployeeLanguage" />
              case 'employee_work_history':
                return <EmployeeWorkHistory key={index} name="EmployeeWorkHistory" />
              case 'employee_education_background':
                return <EmployeeEducationBackground key={index} name="EmployeeEducationBackground" />
              case 'employee_tag':
                return <EmployeeTag key={index} name="EmployeeTag" />
              case undefined:
                return group.key === 'custom_employee_field_group' ? (
                  <CustomFields key={index} group={group} addAnchorTarget />
                ) : null
              default:
                return null
            }
          })}
        <div className="u-ta-c u-mt30">
          {otherButtons}
          <AsyncTaskConsumer>
            {({taskRunningProps}) => (
              <Button
                primary
                onClick={handleSubmit}
                disabled={(needDirty && pristine) || submitting}
                {...taskRunningProps}
              >
                {submitText}
              </Button>
            )}
          </AsyncTaskConsumer>
        </div>
        {shouldShowCancel && (
          <div className="u-ta-c u-mt30">
            <CancelModal
              id={_.get(data, 'id')}
              render={({showModal}) => (
                <a onClick={showModal} className="u-txt-link">
                  依頼を取り下げる
                </a>
              )}
            />
          </div>
        )}
      </div>
      <Master masters={masters(hideEmploymentInfo)} />
    </form>
  )
)

const EmployeeFormInitialized = ({
  data,
  fieldGroups,
  employeeFieldGroups,
  draft,
  nations,
  tmpData,
  tmpFiles,
  procedureType,
  withDiff,
  isAdmin,
  ...rest
}) => {
  const initialValues = useInitialValues({data, draft, tmpData, nations, tmpFiles, procedureType, withDiff, isAdmin})
  const healthInsuranceType = _.get(data, 'office.health_insurance_type')
  const {customGroupIds} = useContext(FieldGroupStore)
  const customEmployeeFieldGroups =
    fieldGroups && customGroupIds !== undefined
      ? fieldGroups.filter((group) => customGroupIds.includes(group.id))
      : fieldGroups
  const fieldGroupsAll =
    employeeFieldGroups &&
    customEmployeeFieldGroups &&
    sortFieldGroupsAll(employeeFieldGroups, customEmployeeFieldGroups)

  return (
    <EmployeeForm
      {...{
        initialValues,
        procedureType,
        healthInsuranceType,
        data,
        fieldGroupsAll,
        ...rest,
      }}
    />
  )
}

export const getCommutingExpenseValues = (commuteType, commutingExpensesObject) => {
  if (_.isEmpty(commutingExpensesObject)) {
    return null
  }
  let commutingExpenses = []
  if (commutingExpensesObject) {
    if (trainTypes.includes(commuteType) && commutingExpensesObject.train) {
      commutingExpenses = [
        ...commutingExpenses,
        ...commutingExpensesObject.train.map((value) => ({...value, commuting_expense_type: 'train'})),
      ]
    }
    if (busTypes.includes(commuteType) && commutingExpensesObject.bus) {
      commutingExpenses = [
        ...commutingExpenses,
        ...commutingExpensesObject.bus.map((value) => ({...value, commuting_expense_type: 'bus'})),
      ]
    }
    if (carTypes.includes(commuteType) && commutingExpensesObject.car) {
      commutingExpenses.push({...commutingExpensesObject.car, commuting_expense_type: 'car'})
    }
  }
  return commutingExpenses
}

export default connect(
  (state, {isInitialInput, procedureType, selector}) => {
    const isAdmin = isAdminSelector(state)
    return {
      nations: state.master.nations,
      fields: fetchSelector(state, 'custom_fields').data,
      isAdmin,
      employee: state.session.currentUser.employee,
      showHealthInsurance:
        isAdmin || (!(isInitialInput || procedureType === 'enroll') && selector(state, 'health_insurance.joined')),
    }
  },
  (dispatch, props) => ({
    onSubmit: (values) =>
      props.onSubmit(
        makeFormValues({values, hideEmploymentInfo: props.hideEmploymentInfo, procedureType: props.procedureType})
      ),
  })
)(EmployeeFormInitialized)
