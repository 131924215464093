import React, {ComponentProps} from 'react'
import {useDispatch} from 'react-redux'
import NotificationsSystem, {Theme, dismissNotification, wyboTheme, SlideTransition} from 'reapop'
import {useSelector} from 'hooks/redux'

const customTheme: Theme = {
  ...wyboTheme,
  notification: (notification) => ({
    ...wyboTheme.notification(notification),
    backgroundColor: '#fbfbfb',
    borderRadius: 0,
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)',
  }),
  notificationMessage: (notification) => ({
    ...wyboTheme.notificationMessage(notification),
    wordBreak: 'break-all',
  }),
}

const CustomSlideTransition = (props: ComponentProps<typeof SlideTransition>) => {
  // デフォルトより少しだけ早くアニメーションさせる
  return <SlideTransition {...props} duration={180} />
}

export const CustomNotifications = () => {
  const dispatch = useDispatch()
  const notifications = useSelector((state) => state.notifications)
  return (
    <NotificationsSystem
      theme={customTheme}
      components={{Transition: CustomSlideTransition}}
      notifications={notifications}
      dismissNotification={(id) => dispatch(dismissNotification(id))}
    />
  )
}
