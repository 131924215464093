import React, {Component} from 'react'
import Button from 'jbc-front/components/Button'
import ActionButton from 'jbc-front/components/ActionButton'

import {FileField, Section} from 'jbc-front/components/Form'
import {reduxForm, getFormSubmitErrors as _getFormSubmitErrors, SubmissionError} from 'redux-form'
const getFormSubmitErrors = _getFormSubmitErrors('file_import')

import styles from 'employees/FileImport.scss'
import api from 'api'
import {connect} from 'react-redux'
import {push} from 'connected-react-router'
import {asyncError} from 'store/actions/asyncError'
import {Link} from 'react-router-dom'
import {Upload} from 'jbc-front/components/icons'
import Modal from 'jbc-front/components/CommonModal'
import Loading from 'components/Loading'
import _ from 'lodash'
import sample from '../../samples/employee.csv'
import {Consumer as AsyncTaskConsumer} from 'AsyncTask'

class ShowMore extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMoreOpen: false,
    }
  }

  handleClickOpen = () => {
    this.setState({
      isMoreOpen: true,
    })
  }

  handleClickClose = () => {
    this.setState({
      isMoreOpen: false,
    })
  }

  render() {
    const {children} = this.props

    return (
      <React.Fragment>
        {this.state.isMoreOpen ? (
          <React.Fragment>
            {children}
            <div className={styles.more} onClick={this.handleClickClose}>
              隠す
            </div>
          </React.Fragment>
        ) : (
          <div className={styles.more} onClick={this.handleClickOpen}>
            もっと見る
          </div>
        )}
      </React.Fragment>
    )
  }
}

export class FileImportDescription extends Component {
  render() {
    const {update} = this.props

    return (
      <div className={styles.tableWrap}>
        <div className="l-overflow-scroll">
          <table className="m-table-description">
            <thead>
              <tr>
                <th className="m-table-description-item-name">データ項目名</th>
                <th>説明文</th>
                <th className="m-table-description-example">例</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>スタッフコード{update && <span className={styles.required}>必須</span>}</th>
                <td>半角英数字50桁以内</td>
                <td>111</td>
              </tr>
              <tr>
                <th>
                  姓<span className={styles.required}>必須</span>
                </th>
                <td>-</td>
                <td>山田</td>
              </tr>
              <tr>
                <th>
                  名<span className={styles.required}>必須</span>
                </th>
                <td>-</td>
                <td>太郎</td>
              </tr>
              <tr>
                <th>姓カナ</th>
                <td>全角カタカナ</td>
                <td>ヤマダ</td>
              </tr>
              <tr>
                <th>名カナ</th>
                <td>全角カタカナ</td>
                <td>タロウ</td>
              </tr>
              <tr>
                <th>ビジネスネーム姓</th>
                <td>
                  この項目に入力してCSVファイルをアップロードした場合、ご利用されている他サービスの姓にビジネスネーム姓が自動反映されます（但しジョブカン給与計算上でビジネスネームが設定済みの従業員に関しては、ビジネスネーム姓に対して自動反映されます）
                </td>
                <td>佐々木</td>
              </tr>
              <tr>
                <th>ビジネスネーム名</th>
                <td>
                  この項目に入力してCSVファイルをアップロードした場合、ご利用されている他サービスの名にビジネスネーム名が自動反映されます（但しジョブカン給与計算上でビジネスネームが設定済みの従業員に関しては、ビジネスネーム名に対して自動反映されます）
                </td>
                <td>太郎</td>
              </tr>
              <tr>
                <th>旧姓 </th>
                <td>-</td>
                <td>佐々木</td>
              </tr>
              <tr>
                <th>生年月日 </th>
                <td>半角数字 (yyyy/mm/dd)</td>
                <td>1980/1/1</td>
              </tr>
              <tr>
                <th>性別 </th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・男性
                  <br />
                  ・女性
                </td>
                <td>男性</td>
              </tr>
              <tr>
                <th>メールアドレス</th>
                <td>-</td>
                <td>yamada@lms.jobcan.ne.jp</td>
              </tr>
              <tr>
                <th>住所 居住国 </th>
                <td>
                  国名または
                  <a
                    href="https://ja.wikipedia.org/wiki/ISO_3166-1"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="u-txt-link"
                  >
                    国コード
                  </a>
                  を入力ください
                  <br />
                  空欄の場合は日本となります
                </td>
                <td>日本</td>
              </tr>
              <tr>
                <th>住所 郵便番号</th>
                <td>半角数字7桁</td>
                <td>999-9999</td>
              </tr>
              <tr>
                <th>住所 都道府県</th>
                <td>-</td>
                <td>東京都</td>
              </tr>
              <tr>
                <th>住所 市区町村</th>
                <td>-</td>
                <td>渋谷区</td>
              </tr>
              <tr>
                <th>住所 丁目番地</th>
                <td>-</td>
                <td>9-99-99</td>
              </tr>
              <tr>
                <th>住所 建物名・部屋番号</th>
                <td>-</td>
                <td>ジョブカンアパート</td>
              </tr>
              <tr>
                <th>住所 カナ</th>
                <td>全角カタカナ</td>
                <td>トウキョウトシブヤク</td>
              </tr>
              <tr>
                <th>海外住所 </th>
                <td>半角英数字</td>
                <td>9999PennsylvaniaAvenueN.W. Washington,DC20004</td>
              </tr>
              <tr>
                <th>電話番号 </th>
                <td>半角数字</td>
                <td>0000-0000-0000</td>
              </tr>
              <tr>
                <th>世帯主の続柄 </th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・本人
                  <br />
                  ・夫
                  <br />
                  ・妻
                  <br />
                  <ShowMore>
                    ・実父
                    <br />
                    ・実母
                    <br />
                    ・義父
                    <br />
                    ・義母
                    <br />
                    ・長男
                    <br />
                    ・長女
                    <br />
                    ・兄
                    <br />
                    ・姉
                    <br />
                    ・弟
                    <br />
                    ・妹
                    <br />
                    ・祖父
                    <br />
                    ・祖母
                    <br />
                    ・叔父
                    <br />
                    ・叔母
                  </ShowMore>
                </td>
                <td>本人</td>
              </tr>
              <tr>
                <th>世帯主の氏名 </th>
                <td>-</td>
                <td>山田太郎</td>
              </tr>
              <tr>
                <th>外国人区分</th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・日本人
                  <br />
                  ・外国人
                  <br />※ 空欄の場合は日本人となります
                </td>
                <td>外国人</td>
              </tr>
              <tr>
                <th>ローマ字氏名 </th>
                <td>半角英数字</td>
                <td>Jobcan Taro</td>
              </tr>
              <tr>
                <th>ローマ字氏名 カナ</th>
                <td>全角カタカナ</td>
                <td>ジョブカン　タロウ</td>
              </tr>
              <tr>
                <th>国籍・地域</th>
                <td>
                  国名または
                  <a
                    href="https://ja.wikipedia.org/wiki/ISO_3166-1"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="u-txt-link"
                  >
                    国コード
                  </a>
                  を入力ください
                  <br />
                  空欄の場合は日本となります
                </td>
                <td>アイスランド</td>
              </tr>
              <tr>
                <th>在留資格 </th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・永住者
                  <br />
                  ・日本人の配偶者等
                  <br />
                  ・永住者の配偶者等
                  <br />
                  ・定住者
                  <br />
                  ・教授
                  <br />
                  <ShowMore>
                    ・芸術
                    <br />
                    ・宗教
                    <br />
                    ・高度専門職1号
                    <br />
                    ・高度専門職2号
                    <br />
                    ・報道
                    <br />
                    ・経営・管理
                    <br />
                    ・法律・会計業務
                    <br />
                    ・医療
                    <br />
                    ・研究
                    <br />
                    ・教育
                    <br />
                    ・技術・人文知識・国際業務
                    <br />
                    ・企業内転勤
                    <br />
                    ・興行
                    <br />
                    ・技能
                    <br />
                    ・技能実習
                    <br />
                    ・文化活動
                    <br />
                    ・短期滞在
                    <br />
                    ・留学
                    <br />
                    ・研修
                    <br />
                    ・家族滞在
                    <br />
                    ・特定活動（ワーキングホリデー）
                    <br />
                    ・特定活動（EPA）
                    <br />
                    ・特定活動（その他）
                    <br />
                    ・特定活動（高度学術研究活動）
                    <br />
                    ・特定活動（高度専門・技術活動）
                    <br />
                    ・特定活動（高度経営・管理活動）
                    <br />
                    ・特定活動（高度人材外国人の就労配偶者）
                    <br />
                    ・特定活動（建設分野）
                    <br />
                    ・特定活動（造船分野）
                    <br />
                    ・特定活動（外国人調理師）
                    <br />
                    ・特定活動（ハラール牛肉生産）
                    <br />
                    ・特定活動（製造分野）
                    <br />
                    ・不明
                  </ShowMore>
                </td>
                <td>永住者</td>
              </tr>
              <tr>
                <th>在留期間満了日 </th>
                <td>半角数字 (yyyy/mm/dd)</td>
                <td>2011/6/1</td>
              </tr>
              <tr>
                <th>資格外活動許可の有無 </th>
                <td>
                  有<br />無
                </td>
                <td>有</td>
              </tr>
              <tr>
                <th>派遣・請負就労区分</th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・派遣・請負労働者として主として当該事業所以外で就労する
                  <br />
                  ・その他
                </td>
                <td>その他</td>
              </tr>
              <tr>
                <th>在留カード番号</th>
                <td>半角英数字12桁</td>
                <td>AB12345678CD</td>
              </tr>
              <tr>
                <th>適用事業所</th>
                <td>
                  <ActionButton as={Link} to="/offices">
                    適用事業所の確認
                  </ActionButton>
                </td>
                <td>本社</td>
              </tr>
              <tr>
                <th>雇用形態コード</th>
                <td>-</td>
                <td>a002</td>
              </tr>
              <tr>
                <th>雇用形態 </th>
                <td>
                  <ActionButton as={Link} to="/settings/employment_types">
                    雇用形態の確認
                  </ActionButton>
                </td>
                <td>正社員</td>
              </tr>
              <tr>
                <th>役職コード</th>
                <td>-</td>
                <td>b003</td>
              </tr>
              <tr>
                <th>役職 </th>
                <td>
                  <ActionButton as={Link} to="/settings/positions">
                    役職の確認
                  </ActionButton>
                </td>
                <td>部長</td>
              </tr>
              <tr>
                <th>職種コード</th>
                <td>-</td>
                <td>sales</td>
              </tr>
              <tr>
                <th>職種 </th>
                <td>
                  <ActionButton as={Link} to="/settings/occupations">
                    職種の確認
                  </ActionButton>
                </td>
                <td>営業</td>
              </tr>
              <tr>
                <th>グループ1コード</th>
                <td>-</td>
                <td>A001</td>
              </tr>
              <tr>
                <th>グループ1</th>
                <td>
                  グループは5階層まで設定できます
                  <ActionButton as={Link} to="/settings/groups" className="u-mt10">
                    グループの確認
                  </ActionButton>
                </td>
                <td>営業部</td>
              </tr>
              <tr>
                <th>グループ2コード</th>
                <td>-</td>
                <td>B001</td>
              </tr>
              <tr>
                <th>グループ2</th>
                <td>
                  グループは5階層まで設定できます
                  <ActionButton as={Link} to="/settings/groups" className="u-mt10">
                    グループの確認
                  </ActionButton>
                </td>
                <td>営業部-{'>'}第一グループ </td>
              </tr>
              <tr>
                <th>グループ3コード</th>
                <td>-</td>
                <td>C001</td>
              </tr>
              <tr>
                <th>グループ3</th>
                <td>
                  グループは5階層まで設定できます
                  <ActionButton as={Link} to="/settings/groups" className="u-mt10">
                    グループの確認
                  </ActionButton>
                </td>
                <td>
                  営業部-{'>'}第一グループ -{'>'}第一チーム
                </td>
              </tr>
              <tr>
                <th>異動日*</th>
                <td>
                  半角数字 (yyyy/mm/dd)
                  <br />
                  「適用事業所」「雇用形態」「役職」「職種」「グループ」の異動日を入力できます。
                  <br />
                  *いずれかを登録する場合、異動日は必須となります。
                </td>
                <td>2011/4/1</td>
              </tr>
              <tr>
                <th>業務内容 </th>
                <td>-</td>
                <td>法人営業</td>
              </tr>
              <tr>
                <th>
                  入社日<span className={styles.required}>必須</span>
                </th>
                <td>半角数字 (yyyy/mm/dd)</td>
                <td>2010/4/1</td>
              </tr>
              <tr>
                <th>退職日</th>
                <td>半角数字 (yyyy/mm/dd)</td>
                <td>2015/4/1</td>
              </tr>
              <tr>
                <th>退職区分 </th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・一般
                  <br />
                  ・障害
                  <br />
                  ・死亡
                </td>
                <td>一般</td>
              </tr>
              <tr>
                <th>退職理由 </th>
                <td>-</td>
                <td>自己都合</td>
              </tr>
              <tr>
                <th>休職開始日</th>
                <td>半角数字 (yyyy/mm/dd)</td>
                <td>2011/4/1</td>
              </tr>
              <tr>
                <th>休職終了日</th>
                <td>半角数字 (yyyy/mm/dd)</td>
                <td>2011/6/1</td>
              </tr>
              <tr>
                <th>休職理由 </th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・私傷病休職
                  <br />
                  ・産前産後休業
                  <br />
                  ・育児休業
                  <br />
                  ・出生時育児休業
                  <br />
                  ・介護休業
                  <br />
                  ・その他
                </td>
                <td>私傷病休職</td>
              </tr>
              <tr>
                <th>契約期間の定め</th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・有
                  <br />
                  ・無
                </td>
                <td>有</td>
              </tr>
              <tr>
                <th>契約期間開始日</th>
                <td>半角数字 (yyyy/mm/dd)</td>
                <td>2012/4/1</td>
              </tr>
              <tr>
                <th>契約期間終了日</th>
                <td>半角数字 (yyyy/mm/dd)</td>
                <td>2012/6/1</td>
              </tr>
              <tr>
                <th>給与形態 </th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・年俸
                  <br />
                  ・月給
                  <br />
                  ・日給
                  <br />
                  ・時給
                </td>
                <td>月給</td>
              </tr>
              <tr>
                <th>基本給</th>
                <td>半角数字</td>
                <td>100000</td>
              </tr>
              <tr>
                <th>報酬月額（通貨）</th>
                <td>半角数字</td>
                <td>100000</td>
              </tr>
              <tr>
                <th>報酬月額（現物）</th>
                <td>半角数字</td>
                <td>0</td>
              </tr>
              <tr>
                <th>標準報酬月額（健康保険） </th>
                <td>半角数字</td>
                <td>98000</td>
              </tr>
              <tr>
                <th>標準報酬月額（厚生年金保険） </th>
                <td>半角数字</td>
                <td>98000</td>
              </tr>
              <tr>
                <th>今年の年間収入見積額 </th>
                <td>半角数字</td>
                <td>1000000</td>
              </tr>
              <tr>
                <th>今年の年間所得見積額 </th>
                <td>半角数字</td>
                <td>350000</td>
              </tr>
              <tr>
                <th>通勤手段 </th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・電車
                  <br />
                  ・バス
                  <br />
                  ・車・二輪車等
                  <br />
                  ・徒歩
                  <br />
                  <ShowMore>
                    ・電車＋バス
                    <br />
                    ・電車＋車・二輪車等
                  </ShowMore>
                </td>
                <td>電車</td>
              </tr>
              <tr>
                <th>電車1 乗駅 </th>
                <td>-</td>
                <td>渋谷</td>
              </tr>
              <tr>
                <th>電車1 降駅 </th>
                <td>-</td>
                <td>新宿</td>
              </tr>
              <tr>
                <th>電車1 経由地</th>
                <td>-</td>
                <td>代々木</td>
              </tr>
              <tr>
                <th>電車1 支給単位 </th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・1ヶ月
                  <br />
                  ・3ヶ月
                  <br />
                  ・6ヶ月
                  <br />
                  ・出勤日数に応じて毎月支給
                </td>
                <td>1ヶ月</td>
              </tr>
              <tr>
                <th>電車1 支給月 </th>
                <td>
                  「支給単位」が「3ヶ月」「6ヶ月」の場合のみ、支給したい月を以下のように入力してください。
                  <br />
                  【3ヶ月】
                  <br />
                  ・1月/4月/7月/10月
                  <br />
                  ・2月/5月/8月/11月
                  <br />
                  ・3月/6月/9月/12月
                  <br />
                  【6ヶ月の場合】
                  <br />
                  ・1月/7月
                  <br />
                  ・2月/8月
                  <br />
                  ・3月/9月
                  <br />
                  ・4月/10月
                  <br />
                  ・5月/11月
                  <br />
                  ・6月/12月
                  <br />
                  支給単位が「1ヶ月」「出勤日数に応じて毎月支給」の場合、空欄のままでお願いします。
                </td>
                <td></td>
              </tr>
              <tr>
                <th>電車1 片道運賃 </th>
                <td>半角数字</td>
                <td>100</td>
              </tr>
              <tr>
                <th>電車1 定期代</th>
                <td>半角数字</td>
                <td>10000</td>
              </tr>
              <tr>
                <th>電車2 乗駅 </th>
                <td>-</td>
                <td>渋谷</td>
              </tr>
              <tr>
                <th>電車2 降駅 </th>
                <td>-</td>
                <td>新宿</td>
              </tr>
              <tr>
                <th>電車2 経由地</th>
                <td>-</td>
                <td>代々木</td>
              </tr>
              <tr>
                <th>電車2 支給単位 </th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・1ヶ月
                  <br />
                  ・3ヶ月
                  <br />
                  ・6ヶ月
                  <br />
                  ・出勤日数に応じて毎月支給
                </td>
                <td>1ヶ月</td>
              </tr>
              <tr>
                <th>電車2 支給月 </th>
                <td>
                  「支給単位」が「3ヶ月」「6ヶ月」の場合のみ、支給したい月を以下のように入力してください。
                  <br />
                  【3ヶ月】
                  <br />
                  ・1月/4月/7月/10月
                  <br />
                  ・2月/5月/8月/11月
                  <br />
                  ・3月/6月/9月/12月
                  <br />
                  【6ヶ月の場合】
                  <br />
                  ・1月/7月
                  <br />
                  ・2月/8月
                  <br />
                  ・3月/9月
                  <br />
                  ・4月/10月
                  <br />
                  ・5月/11月
                  <br />
                  ・6月/12月
                  <br />
                  支給単位が「1ヶ月」「出勤日数に応じて毎月支給」の場合、空欄のままでお願いします。
                </td>
                <td></td>
              </tr>
              <tr>
                <th>電車2 片道運賃 </th>
                <td>半角数字</td>
                <td>100</td>
              </tr>
              <tr>
                <th>電車2 定期代</th>
                <td>半角数字</td>
                <td>10000</td>
              </tr>
              <tr>
                <th>バス1 乗車停留所</th>
                <td>-</td>
                <td>渋谷</td>
              </tr>
              <tr>
                <th>バス1 降車停留所</th>
                <td>-</td>
                <td>新宿</td>
              </tr>
              <tr>
                <th>バス1 経由地</th>
                <td>-</td>
                <td>代々木</td>
              </tr>
              <tr>
                <th>バス1 支給単位 </th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・1ヶ月
                  <br />
                  ・3ヶ月
                  <br />
                  ・6ヶ月
                  <br />
                  ・出勤日数に応じて毎月支給
                </td>
                <td>1ヶ月</td>
              </tr>
              <tr>
                <th>バス1 支給月 </th>
                <td>
                  「支給単位」が「3ヶ月」「6ヶ月」の場合のみ、支給したい月を以下のように入力してください。
                  <br />
                  【3ヶ月】
                  <br />
                  ・1月/4月/7月/10月
                  <br />
                  ・2月/5月/8月/11月
                  <br />
                  ・3月/6月/9月/12月
                  <br />
                  【6ヶ月の場合】
                  <br />
                  ・1月/7月
                  <br />
                  ・2月/8月
                  <br />
                  ・3月/9月
                  <br />
                  ・4月/10月
                  <br />
                  ・5月/11月
                  <br />
                  ・6月/12月
                  <br />
                  支給単位が「1ヶ月」「出勤日数に応じて毎月支給」の場合、空欄のままでお願いします。
                </td>
                <td></td>
              </tr>
              <tr>
                <th>バス1 片道運賃 </th>
                <td>半角数字</td>
                <td>100</td>
              </tr>
              <tr>
                <th>バス1 定期代</th>
                <td>半角数字</td>
                <td>10000</td>
              </tr>
              <tr>
                <th>バス2 乗車停留所</th>
                <td>-</td>
                <td>渋谷</td>
              </tr>
              <tr>
                <th>バス2 降車停留所</th>
                <td>-</td>
                <td>新宿</td>
              </tr>
              <tr>
                <th>バス2 経由地</th>
                <td>-</td>
                <td>代々木</td>
              </tr>
              <tr>
                <th>バス2 支給単位 </th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・1ヶ月
                  <br />
                  ・3ヶ月
                  <br />
                  ・6ヶ月
                  <br />
                  ・出勤日数に応じて毎月支給
                </td>
                <td>1ヶ月</td>
              </tr>
              <tr>
                <th>バス2 支給月 </th>
                <td>
                  「支給単位」が「3ヶ月」「6ヶ月」の場合のみ、支給したい月を以下のように入力してください。
                  <br />
                  【3ヶ月】
                  <br />
                  ・1月/4月/7月/10月
                  <br />
                  ・2月/5月/8月/11月
                  <br />
                  ・3月/6月/9月/12月
                  <br />
                  【6ヶ月の場合】
                  <br />
                  ・1月/7月
                  <br />
                  ・2月/8月
                  <br />
                  ・3月/9月
                  <br />
                  ・4月/10月
                  <br />
                  ・5月/11月
                  <br />
                  ・6月/12月
                  <br />
                  支給単位が「1ヶ月」「出勤日数に応じて毎月支給」の場合、空欄のままでお願いします。
                </td>
                <td></td>
              </tr>
              <tr>
                <th>バス2 片道運賃 </th>
                <td>半角数字</td>
                <td>100</td>
              </tr>
              <tr>
                <th>バス2 定期代</th>
                <td>半角数字</td>
                <td>10000</td>
              </tr>
              <tr>
                <th>車・二輪車等 車両種別</th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・車
                  <br />
                  ・バイク
                  <br />
                  ・原付
                  <br />
                  ・自転車
                  <br />
                </td>
                <td>車</td>
              </tr>
              <tr>
                <th>車・二輪車等 出発地</th>
                <td>-</td>
                <td>自宅</td>
              </tr>
              <tr>
                <th>車・二輪車等 到着地</th>
                <td>-</td>
                <td>本社</td>
              </tr>
              <tr>
                <th>車・二輪車等 片道の通勤距離</th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・2km未満
                  <br />
                  ・2km～10km
                  <br />
                  ・10km～15km
                  <br />
                  <ShowMore>
                    ・15km～25km
                    <br />
                    ・25km～35km
                    <br />
                    ・35km～45km
                    <br />
                    ・45km～55km
                    <br />
                    ・55km以上
                  </ShowMore>
                </td>
                <td>2km～10km</td>
              </tr>
              <tr>
                <th>車・二輪車等 支給単位 </th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・1ヶ月
                  <br />
                  ・3ヶ月
                  <br />
                  ・6ヶ月
                  <br />
                  ・出勤日数に応じて毎月支給
                </td>
                <td>1ヶ月</td>
              </tr>
              <tr>
                <th>車・二輪車等 支給月 </th>
                <td>
                  「支給単位」が「3ヶ月」「6ヶ月」の場合のみ、支給したい月を以下のように入力してください。
                  <br />
                  【3ヶ月】
                  <br />
                  ・1月/4月/7月/10月
                  <br />
                  ・2月/5月/8月/11月
                  <br />
                  ・3月/6月/9月/12月
                  <br />
                  【6ヶ月の場合】
                  <br />
                  ・1月/7月
                  <br />
                  ・2月/8月
                  <br />
                  ・3月/9月
                  <br />
                  ・4月/10月
                  <br />
                  ・5月/11月
                  <br />
                  ・6月/12月
                  <br />
                  支給単位が「1ヶ月」「出勤日数に応じて毎月支給」の場合、空欄のままでお願いします。
                </td>
                <td></td>
              </tr>
              <tr>
                <th>車・二輪車等 支給額</th>
                <td>半角数字</td>
                <td>10000</td>
              </tr>
              <tr>
                <th>銀行コード</th>
                <td>半角数字4桁</td>
                <td>1111</td>
              </tr>
              <tr>
                <th>銀行名</th>
                <td>空欄の場合、下の銀行コードに従って自動で入力されます</td>
                <td>ジョブカン銀行</td>
              </tr>
              <tr>
                <th>支店コード</th>
                <td>半角数字3桁</td>
                <td>111</td>
              </tr>
              <tr>
                <th>支店名</th>
                <td>空欄の場合、下の支店コードに従って自動で入力されます</td>
                <td>ジョブカン支店</td>
              </tr>
              <tr>
                <th>預金種別 </th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・普通
                  <br />
                  ・当座
                </td>
                <td>普通</td>
              </tr>
              <tr>
                <th>口座番号 </th>
                <td>半角数字7桁以内</td>
                <td>1111111</td>
              </tr>
              <tr>
                <th>口座名義（カナ） </th>
                <td>カナ30文字以内</td>
                <td>ヤマダ　タロウ</td>
              </tr>
              <tr>
                <th>緊急連絡先 姓</th>
                <td>-</td>
                <td>山田</td>
              </tr>
              <tr>
                <th>緊急連絡先 名</th>
                <td>-</td>
                <td>一郎</td>
              </tr>
              <tr>
                <th>緊急連絡先 姓カナ</th>
                <td>全角カタカナ</td>
                <td>ヤマダ</td>
              </tr>
              <tr>
                <th>緊急連絡先 名カナ</th>
                <td>全角カタカナ</td>
                <td>イチロウ</td>
              </tr>
              <tr>
                <th>緊急連絡先 続柄 </th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・夫
                  <br />
                  ・妻
                  <br />
                  ・実父
                  <br />
                  ・実母
                  <br />
                  <ShowMore>
                    ・義父
                    <br />
                    ・義母
                    <br />
                    ・長男
                    <br />
                    ・長女
                    <br />
                    ・次男
                    <br />
                    ・次女
                    <br />
                    ・三男
                    <br />
                    ・三女
                    <br />
                    ・兄
                    <br />
                    ・姉
                    <br />
                    ・弟
                    <br />
                    ・妹
                    <br />
                    ・祖父
                    <br />
                    ・祖母
                    <br />
                    ・孫息子
                    <br />
                    ・孫娘
                    <br />
                    ・叔父
                    <br />
                    ・叔母
                    <br />
                    ・甥
                    <br />
                    ・姪
                  </ShowMore>
                </td>
                <td>実父</td>
              </tr>
              <tr>
                <th>緊急連絡先 電話番号 </th>
                <td>半角数字</td>
                <td>0000-0000-0000</td>
              </tr>
              <tr>
                <th>緊急連絡先 郵便番号 </th>
                <td>半角数字7桁</td>
                <td>999-9999</td>
              </tr>
              <tr>
                <th>緊急連絡先 都道府県 </th>
                <td>-</td>
                <td>東京都</td>
              </tr>
              <tr>
                <th>緊急連絡先 市区町村 </th>
                <td>-</td>
                <td>渋谷区</td>
              </tr>
              <tr>
                <th>緊急連絡先 丁目番地 </th>
                <td>-</td>
                <td>9-99-99</td>
              </tr>
              <tr>
                <th>緊急連絡先 建物名・部屋番号 </th>
                <td>-</td>
                <td>ジョブカンアパート</td>
              </tr>
              <tr>
                <th>緊急連絡先 住所カナ </th>
                <td>全角カタカナ</td>
                <td>トウキョウトシブヤク</td>
              </tr>
              <tr>
                <th>住民票住所 郵便番号 </th>
                <td>半角数字7桁</td>
                <td>999-9999</td>
              </tr>
              <tr>
                <th>住民票住所 都道府県 </th>
                <td>-</td>
                <td>東京都</td>
              </tr>
              <tr>
                <th>住民票住所 市区町村 </th>
                <td>-</td>
                <td>渋谷区</td>
              </tr>
              <tr>
                <th>住民票住所 丁目番地 </th>
                <td>-</td>
                <td>9-99-99</td>
              </tr>
              <tr>
                <th>住民票住所 建物名・部屋番号 </th>
                <td>-</td>
                <td>ジョブカンアパート</td>
              </tr>
              <tr>
                <th>住民票住所 住所カナ </th>
                <td>全角カタカナ</td>
                <td>トウキョウトシブヤク</td>
              </tr>
              <tr>
                <th>非居住者区分 </th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・対象外
                  <br />
                  ・非居住者
                </td>
                <td>非居住者</td>
              </tr>
              <tr>
                <th>税額表区分 </th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・甲欄
                  <br />
                  ・乙欄
                  <br />
                  ・所得税を計算しない
                </td>
                <td>甲欄</td>
              </tr>
              <tr>
                <th>給与支払報告書提出先 </th>
                <td>空欄の場合、給与支払報告書提出先コードに従って自動で入力されます</td>
                <td>東京都渋谷区</td>
              </tr>
              <tr>
                <th>給与支払報告書提出先（市区町村コード） </th>
                <td>半角数字6桁以内</td>
                <td>131130</td>
              </tr>
              <tr>
                <th>住民税徴収方法 </th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・特別徴収
                  <br />
                  ・普通徴収
                </td>
                <td>特別徴収</td>
              </tr>
              <tr>
                <th>障害者区分</th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・対象外
                  <br />
                  ・一般障害者
                  <br />
                  ・特別障害者
                </td>
                <td>一般障害者</td>
              </tr>
              <tr>
                <th>障害者区分 詳細情報 </th>
                <td>障害者手帳の種類と交付年月日、障害の等級などを入力してください</td>
                <td>身体障害者手帳（平成25年4月1日交付）・身体障害者2級</td>
              </tr>
              <tr>
                <th>勤労学生区分 </th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・対象外
                  <br />
                  ・勤労学生
                </td>
                <td>勤労学生</td>
              </tr>
              <tr>
                <th>勤労学生区分 詳細情報</th>
                <td>勤労学生の場合は学校名や入学年月日を入力してください</td>
                <td>ジョブカン大学（平成30年4月1日入学）</td>
              </tr>
              <tr>
                <th>ひとり親・寡婦区分</th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・対象外
                  <br />
                  ・ひとり親
                  <br />
                  ・寡婦
                </td>
                <td>ひとり親</td>
              </tr>
              <tr>
                <th>ひとり親・寡婦区分 詳細情報 </th>
                <td>ひとり親・寡婦区分に関連する情報を入力してください</td>
                <td>離婚</td>
              </tr>
              <tr>
                <th>寡婦（夫）区分</th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・対象外
                  <br />
                  ・寡婦（夫）
                  <br />
                  ・特別寡婦
                </td>
                <td>寡婦（夫）</td>
              </tr>
              <tr>
                <th>寡婦（夫）区分 詳細情報 </th>
                <td>寡婦（夫）区分に関連する情報を入力してください</td>
                <td>離婚</td>
              </tr>
              <tr>
                <th>災害者区分</th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・対象外
                  <br />
                  ・災害者
                </td>
                <td>災害者</td>
              </tr>
              <tr>
                <th>健康保険被保険者整理番号 </th>
                <td>半角数字7桁以内</td>
                <td>1111111</td>
              </tr>
              <tr>
                <th>健康保険 加入状況</th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・加入
                  <br />
                  ・未加入
                  <br />
                  ※空欄の場合は未加入となります
                </td>
                <td>加入</td>
              </tr>
              <tr>
                <th>健康保険 資格取得日 </th>
                <td>半角数字 (yyyy/mm/dd)</td>
                <td>2011/4/1</td>
              </tr>
              <tr>
                <th>健康保険 資格喪失日 </th>
                <td>半角数字 (yyyy/mm/dd)</td>
                <td>2011/6/1</td>
              </tr>
              <tr>
                <th>基礎年金番号 </th>
                <td>半角数字10桁</td>
                <td>1111-111111</td>
              </tr>
              <tr>
                <th>厚生年金保険被保険者整理番号</th>
                <td>
                  半角数字6桁以内
                  <br />
                  ※協会けんぽご利用の場合記載しないでください
                </td>
                <td>111111</td>
              </tr>
              <tr>
                <th>厚生年金保険 加入状況</th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・加入
                  <br />
                  ・未加入
                  <br />
                  ※空欄の場合は未加入となります
                </td>
                <td>加入</td>
              </tr>
              <tr>
                <th>厚生年金保険 資格取得日 </th>
                <td>半角数字 (yyyy/mm/dd)</td>
                <td>2011/4/1</td>
              </tr>
              <tr>
                <th>厚生年金保険 資格喪失日 </th>
                <td>半角数字 (yyyy/mm/dd)</td>
                <td>2011/6/1</td>
              </tr>
              <tr>
                <th>雇用保険 被保険者番号</th>
                <td>半角数字11桁</td>
                <td>1111-111111-1</td>
              </tr>
              <tr>
                <th>雇用保険 加入状況</th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・加入
                  <br />
                  ・未加入
                  <br />
                  ※空欄の場合は未加入となります
                </td>
                <td>加入</td>
              </tr>
              <tr>
                <th>雇用保険 資格取得日 </th>
                <td>半角数字 (yyyy/mm/dd)</td>
                <td>2011/4/1</td>
              </tr>
              <tr>
                <th>雇用保険 離職等年月日 </th>
                <td>半角数字 (yyyy/mm/dd)</td>
                <td>2011/6/1</td>
              </tr>
              <tr>
                <th>配偶者の有無 </th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・有
                  <br />
                  ・無
                </td>
                <td>有</td>
              </tr>
              <tr>
                <th>配偶者の年間収入（去年）</th>
                <td>半角数字</td>
                <td>1000000</td>
              </tr>
              <tr>
                <th>配偶者の月額収入（現在）</th>
                <td>半角数字</td>
                <td>100000</td>
              </tr>
              <tr>
                <th>扶養の有無</th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・有
                  <br />
                  ・無
                </td>
                <td>無</td>
              </tr>
              <tr>
                <th>従たる給与についての扶養控除等申告書の提出</th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・TRUE
                  <br />
                  ・FALSE
                  <br />※ 複数掛け持ちで2社以上から給料を受け取っている場合に提出が必要
                </td>
                <td>TRUE</td>
              </tr>
              <tr>
                <th>被扶養者1 続柄 </th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・配偶者
                  <br />
                  ・実父
                  <br />
                  ・実母
                  <br />
                  <ShowMore>
                    ・義父
                    <br />
                    ・義母
                    <br />
                    ・長男
                    <br />
                    ・長女
                    <br />
                    ・次男
                    <br />
                    ・次女
                    <br />
                    ・三男
                    <br />
                    ・三女
                    <br />
                    ・兄
                    <br />
                    ・姉
                    <br />
                    ・弟
                    <br />
                    ・妹
                    <br />
                    ・祖父
                    <br />
                    ・祖母
                    <br />
                    ・孫息子
                    <br />
                    ・孫娘
                    <br />
                    ・叔父
                    <br />
                    ・叔母
                    <br />
                    ・甥
                    <br />
                    ・姪
                  </ShowMore>
                </td>
                <td>配偶者</td>
              </tr>
              <tr>
                <th>被扶養者1 姓</th>
                <td>-</td>
                <td>山田</td>
              </tr>
              <tr>
                <th>被扶養者1 名</th>
                <td>-</td>
                <td>花子</td>
              </tr>
              <tr>
                <th>被扶養者1 姓カナ</th>
                <td>全角カタカナ</td>
                <td>ヤマダ</td>
              </tr>
              <tr>
                <th>被扶養者1 名カナ</th>
                <td>全角カタカナ</td>
                <td>ハナコ</td>
              </tr>
              <tr>
                <th>被扶養者1 生年月日 </th>
                <td>
                  半角数字 (yyyy/mm/dd)
                  <br />
                  ※被扶養者の情報を登録する場合は入力必須となります
                </td>
                <td>1980/1/1</td>
              </tr>
              <tr>
                <th>被扶養者1 性別 </th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・男性
                  <br />
                  ・女性
                </td>
                <td>女性</td>
              </tr>
              <tr>
                <th>被扶養者1 同居・別居</th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・同居
                  <br />
                  ・別居（国内）
                  <br />
                  ・別居（国外）
                </td>
                <td>同居</td>
              </tr>
              <tr>
                <th>被扶養者1 郵便番号 </th>
                <td>半角数字7桁</td>
                <td>999-9999</td>
              </tr>
              <tr>
                <th>被扶養者1 都道府県 </th>
                <td>-</td>
                <td>東京都</td>
              </tr>
              <tr>
                <th>被扶養者1 市区町村 </th>
                <td>-</td>
                <td>渋谷区</td>
              </tr>
              <tr>
                <th>被扶養者1 丁目番地 </th>
                <td>-</td>
                <td>9-99-99</td>
              </tr>
              <tr>
                <th>被扶養者1 建物名・部屋番号 </th>
                <td>-</td>
                <td>ジョブカンアパート</td>
              </tr>
              <tr>
                <th>被扶養者1 住所カナ </th>
                <td>全角カタカナ</td>
                <td>トウキョウトシブヤク</td>
              </tr>
              <tr>
                <th>被扶養者1 海外住所 </th>
                <td>半角英数字</td>
                <td>9999PennsylvaniaAvenueN.W. Washington,DC20004</td>
              </tr>
              <tr>
                <th>被扶養者1 非居住者 </th>
                <td>下記より一つ選択してください ・対象外 ・非居住者</td>
                <td>非居住者</td>
              </tr>
              <tr>
                <th>被扶養者1 留学 </th>
                <td>下記より一つ選択してください ・対象外 ・留学</td>
                <td>留学</td>
              </tr>
              <tr>
                <th>被扶養者1 電話番号 </th>
                <td>半角数字</td>
                <td>0000-0000-0000</td>
              </tr>
              <tr>
                <th>被扶養者1 基礎年金番号　※ 配偶者のみ</th>
                <td>半角数字10桁</td>
                <td>1111-111111</td>
              </tr>
              <tr>
                <th>被扶養者1 職業 </th>
                <td>
                  以下のように12文字以内で入力してください。
                  <br />
                  <div className="u-ta-l u-mt10">
                    配偶者の場合・・・「主婦」「会社員」など
                    <br />
                    学生の場合・・・「小学生」「中学生」16歳以上の学生の場合は「高校◯年」「大学◯年」
                    <br />
                    乳幼児の場合・・・満1歳に満たない場合は「乳児」満1歳から小学校入学までは「幼児」
                    <br />
                    その他・・・「年金受給者」「無職」など
                  </div>
                </td>
                <td>主婦</td>
              </tr>
              <tr>
                <th>被扶養者1 外国人区分　※ 配偶者のみ </th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・日本人
                  <br />
                  ・外国人
                  <br />※ 配偶者でない方は入力の必要がありません。空の場合は自動で日本人が入ります。
                </td>
                <td>外国人</td>
              </tr>
              <tr>
                <th>被扶養者1 ローマ字氏名　※ 配偶者のみ</th>
                <td>
                  半角英数字
                  <br />
                  ※配偶者でない方は入力の必要がありません。
                </td>
                <td>Yamada Hanako</td>
              </tr>
              <tr>
                <th>被扶養者1 ローマ字氏名カナ　※ 配偶者のみ</th>
                <td>
                  全角カタカナ
                  <br />
                  ※配偶者でない方は入力の必要がありません。
                </td>
                <td>ヤマダ　ハナコ</td>
              </tr>
              <tr>
                <th>被扶養者1 年間収入（今後1年間見込）</th>
                <td>半角数字</td>
                <td>1000000</td>
              </tr>
              <tr>
                <th>被扶養者1 年間所得（1～12月）</th>
                <td>半角数字</td>
                <td>350000</td>
              </tr>
              <tr>
                <th>被扶養者1 ?税の扶養対象</th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・対象
                  <br />
                  ・対象外
                </td>
                <td>対象</td>
              </tr>
              <tr>
                <th>被扶養者1 障害者区分</th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・対象外
                  <br />
                  ・一般障害者
                  <br />
                  ・特別障害者
                  <br />
                  ※空欄の場合は対象外となります
                </td>
                <td>一般障害者</td>
              </tr>
              <tr>
                <th>被扶養者1 障害者区分 詳細情報 </th>
                <td>障害者手帳の種類と交付年月日、障害の等級などを入力してください</td>
                <td>身体障害者手帳（平成25年4月1日交付）・身体障害者2級</td>
              </tr>
              <tr>
                <th>被扶養者1 被扶養者になった日</th>
                <td>半角数字 (yyyy/mm/dd)</td>
                <td>2011/4/1</td>
              </tr>
              <tr>
                <th>被扶養者1 扶養に追加された理由 </th>
                <td>-</td>
                <td>結婚</td>
              </tr>
              {update && (
                <tr>
                  <th>被扶養者1 削除フラグ </th>
                  <td>半角数字「1」を設定すると対象の被扶養情報を削除します</td>
                  <td>1</td>
                </tr>
              )}
              <tr>
                <th>社内連絡先 電話番号</th>
                <td>半角数字</td>
                <td>0000-0000-0000</td>
              </tr>
              <tr>
                <th>社内連絡先 内線番号</th>
                <td>半角数字</td>
                <td>111</td>
              </tr>
              <tr>
                <th>社内連絡先 メールアドレス</th>
                <td>-</td>
                <td>yamada@lms.jobcan.ne.jp</td>
              </tr>
              <tr>
                <th>職歴1 会社名</th>
                <td>-</td>
                <td>株式会社ジョブカン</td>
              </tr>
              <tr>
                <th>職歴1 入社年月日</th>
                <td>半角数字 (yyyy/mm/dd)</td>
                <td>2011/4/1</td>
              </tr>
              <tr>
                <th>職歴1 退社年月日</th>
                <td>半角数字 (yyyy/mm/dd)</td>
                <td>2011/6/1</td>
              </tr>
              <tr>
                <th>職歴1 役職・職位</th>
                <td>-</td>
                <td>部長</td>
              </tr>
              <tr>
                <th>職歴1 業務内容</th>
                <td>-</td>
                <td>営業</td>
              </tr>
              <tr>
                <th>学歴1 学校区分</th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・大学院
                  <br />
                  ・大学
                  <br />
                  ・短大
                  <br />
                  ・高専
                  <br />
                  ・専門学校
                  <br />
                  ・高校
                  <br />
                  ・中学
                  <br />
                  ・その他
                </td>
                <td>大学</td>
              </tr>
              <tr>
                <th>学歴1 学校名</th>
                <td>-</td>
                <td>ジョブカン大学</td>
              </tr>
              <tr>
                <th>学歴1 学部学科専攻</th>
                <td>-</td>
                <td>工学部</td>
              </tr>
              <tr>
                <th>学歴1 入学年月日</th>
                <td>半角数字 (yyyy/mm/dd)</td>
                <td>2011/4/1</td>
              </tr>
              <tr>
                <th>学歴1 卒業年月日</th>
                <td>半角数字 (yyyy/mm/dd)</td>
                <td>2015/3/31</td>
              </tr>
              <tr>
                <th>学歴1 卒業区分</th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・卒業
                  <br />
                  ・中退
                </td>
                <td>卒業</td>
              </tr>
              <tr>
                <th>資格1 資格名</th>
                <td>-</td>
                <td>ジョブカン検定</td>
              </tr>
              <tr>
                <th>資格1 取得更新年月日</th>
                <td>半角数字 (yyyy/mm/dd)</td>
                <td>2011/4/1</td>
              </tr>
              <tr>
                <th>資格1 有効期限</th>
                <td>半角数字 (yyyy/mm/dd)</td>
                <td>2012/3/31</td>
              </tr>
              <tr>
                <th>資格1 資格登録番号</th>
                <td>-</td>
                <td>1111</td>
              </tr>
              <tr>
                <th>語学スキル1 言語</th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・英語
                  <br />
                  ・日本語
                  <br />
                  <ShowMore>
                    ・北京語
                    <br />
                    ・広東語
                    <br />
                    ・韓国語・朝鮮語
                    <br />
                    ・台湾語
                    <br />
                    ・アラビア語
                    <br />
                    ・スペイン語
                    <br />
                    ・フィンランド語
                    <br />
                    ・フランス語
                    <br />
                    ・ドイツ語
                    <br />
                    ・ヘブライ語
                    <br />
                    ・ヒンディー語
                    <br />
                    ・インドネシア語
                    <br />
                    ・イタリア語
                    <br />
                    ・マレーシア語
                    <br />
                    ・オランダ語
                    <br />
                    ・ノルウェー語
                    <br />
                    ・ポルトガル語
                    <br />
                    ・ロシア語
                    <br />
                    ・スウェーデン語
                    <br />
                    ・スワヒリ語
                    <br />
                    ・タイ語
                    <br />
                    ・タガログ語
                    <br />
                    ・ベトナム語
                  </ShowMore>
                </td>
                <td>英語</td>
              </tr>
              <tr>
                <th>語学スキル1 レベル</th>
                <td>
                  下記より一つ選択してください
                  <br />
                  ・ネイティブ
                  <br />
                  ・ビジネス会話
                  <br />
                  ・日常会話
                  <br />
                  ・基礎会話
                  <br />
                  ・なし
                </td>
                <td>日常会話</td>
              </tr>
              <tr>
                <th>語学スキル1 資格名</th>
                <td>-</td>
                <td>TOEIC</td>
              </tr>
              <tr>
                <th>語学スキル1 点数</th>
                <td>半角数字</td>
                <td>600</td>
              </tr>
              <tr>
                <th>語学スキル1 受験日</th>
                <td>半角数字 (yyyy/mm/dd)</td>
                <td>2011/4/1</td>
              </tr>
              <tr>
                <th>プロジェクト1 プロジェクト名</th>
                <td>-</td>
                <td>ジョブカンプロジェクト</td>
              </tr>
              <tr>
                <th>プロジェクト1 開始年月日</th>
                <td>半角数字 (yyyy/mm/dd)</td>
                <td>2011/4/1</td>
              </tr>
              <tr>
                <th>プロジェクト1 終了年月日</th>
                <td>半角数字 (yyyy/mm/dd)</td>
                <td>2011/6/1</td>
              </tr>
              <tr>
                <th>プロジェクト1 プロジェクト詳細</th>
                <td>-</td>
                <td>ジョブカンのサービス立ち上げ</td>
              </tr>
              <tr>
                <th>スキル1 スキル名</th>
                <td>-</td>
                <td>接客</td>
              </tr>
              <tr>
                <th>スキル1 習得年月日</th>
                <td>半角数字 (yyyy/mm/dd)</td>
                <td>2011/4/1</td>
              </tr>
              <tr>
                <th>スキル1 スキル詳細</th>
                <td>-</td>
                <td>2011年に社内MVPを受賞</td>
              </tr>
              <tr>
                <th>タグ1</th>
                <td>-</td>
                <td>社内MVP</td>
              </tr>
              {update && (
                <tr>
                  <th>削除フラグ</th>
                  <td>半角数字「1」を設定すると対象の従業員情報を削除します</td>
                  <td>1</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export const validate = (values) => {
  const errors = {}
  if (_.isEmpty(values.file)) {
    errors.file = 'ファイルを選択してください'
  }
  return errors
}

const initialValues = {csv_update: false}

const Form = reduxForm({
  form: 'file_import',
  enableReinitialize: true,
  validate,
})(({submitting, handleSubmit}) => (
  <form onSubmit={handleSubmit} className={`u-mt20 ${styles.form}`}>
    <div className="u-mb40">
      <FileField name="file" />
    </div>
    <Modal
      isOpen={submitting}
      bodyOpenClassName={styles.loadingModalBody}
      className={styles.loadingModal}
      overlayClassName={styles.loadingModalOverlay}
    >
      <Modal.Body>
        <Loading />
      </Modal.Body>
    </Modal>
    <AsyncTaskConsumer>
      {({taskRunningProps}) => (
        <Button
          as="button"
          primary
          type="submit"
          disabled={submitting}
          icon={<Upload />}
          widthWide
          {...taskRunningProps}
        >
          従業員登録
        </Button>
      )}
    </AsyncTaskConsumer>
  </form>
))

class FileImport extends Component {
  handleSubmit = (values) => {
    const {token, importEmployees} = this.props
    return importEmployees(token, values)
  }

  render() {
    const {errors} = this.props
    return (
      <div>
        <div className="l-title-wrap u-pb30">
          <h1 className="m-title-main">新規従業員登録</h1>
          <div className={`u-mt60 ${styles.mainNoteWrap}`}>
            <div className={styles.mainNoteTitle}>新規に登録したい従業員情報を記載したファイルを選択してください</div>
            <div className={styles.mainNoteBody}>
              <div className={styles.mainNote}>対応しているファイル形式はCSVとなります</div>
              <div className={styles.mainNote}>
                <a className="u-txt-link" href={sample} target="_blank" rel="noopener" download="sample.csv">
                  サンプルをダウンロード
                </a>
              </div>
              <Form onSubmit={this.handleSubmit} initialValues={initialValues} />
            </div>
          </div>
        </div>
        {errors && errors.rows && (
          <Section title="ファイル内容に誤りがあります" error>
            <ul>
              {errors.rows.map((err) => (
                <li key={err.index}>
                  第{err.index + 1}行：{err.name}
                  {err.message}
                </li>
              ))}
            </ul>
          </Section>
        )}
        <div className={styles.warningWrap}>
          <dt className={styles.warningTitle}>【注意】</dt>
          <dd>
            登録するユーザが、ジョブカン他サービスにも登録されている場合、
            <span className={styles.warning}>
              入力された姓名、スタッフコードは、ご利用中のジョブカン他サービスにも自動反映されます。またビジネスネーム姓名に入力された場合、ビジネスネーム姓名が他サービスの姓名に自動反映されます。
              （但しジョブカン給与計算でビジネスネームが設定済みの従業員に関しては、ビジネスネーム姓名に対して自動反映されます）
            </span>
          </dd>
        </div>
        <div className="u-mt50">
          <FileImportDescription />
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    token: state.auth.token,
    errors: getFormSubmitErrors(state),
  }),
  (dispatch) => ({
    async importEmployees(token, values) {
      try {
        await api.createWithAuth(token).employees.import(values)
        dispatch(push('/employees'))
      } catch (err) {
        if (err.response && err.response.status == 422 && _.get(err.response, 'data._errors')) {
          throw new SubmissionError(err.response.data._errors)
        }
        dispatch(asyncError(err))
      }
    },
  })
)(FileImport)
