import {useDispatch} from 'react-redux'
import {notify, Status} from 'reapop'

export const useNotify = () => {
  const dispatch = useDispatch()
  return (message: string, status: Status) =>
    dispatch(
      notify({
        message: `${message}`,
        position: 'top-center',
        status,
        dismissAfter: 3000,
      })
    )
}
