import {FC} from 'react'
import {Link} from 'react-router-dom'
import {getMainOfficeContactInfo} from 'libs/officeUtils'
import {Header} from 'components/layout/Header'
import {TmLogo} from './parts/Logo'
import {Help} from './parts/Help'
import {MainOffice} from './parts/MainOffice'
import {UserMenu} from './parts/UserMenu'
import {TmNotVerified} from './parts/NotVerified'

import type {TmUser} from 'types/api/tm/tmUsers/tmUser'
import type {CurrentClientForAdmin} from 'types/api/clients/currentClient'

interface TmLoggedInHeaderProps {
  client?: CurrentClientForAdmin
  currentTmUser: TmUser | Record<string, never>
}

export const TmLoggedInHeader: FC<TmLoggedInHeaderProps> = ({client, currentTmUser = {}}) => {
  const name = currentTmUser.employee?.display_full_name || currentTmUser.email
  const mainOfficeSocial = getMainOfficeContactInfo(client?.office)
  const isNotVerified = client && 'is_verified' in client && !client.is_verified

  return (
    <Header.Content>
      <Link to="/tm/employees">
        <TmLogo />
      </Link>
      {isNotVerified && <TmNotVerified />}
      <Help />
      {mainOfficeSocial?.name && <MainOffice name={mainOfficeSocial.name} />}
      <UserMenu name={name} />
    </Header.Content>
  )
}
