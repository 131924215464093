import {gql} from '@apollo/client'

export const SEARCH_PAYROLL_REPORT_CITIES = gql`
  query SearchPayrollReportCities($per: Int!, $page: Int!, $search: String) {
    client {
      id
      searchPayrollReportCities(per: $per, page: $page, search: $search) {
        totalCount
        list {
          id
          name
          organizationCode
          prefectureName
        }
      }
    }
  }
`
export const SEARCH_PAYROLL_REPORT_CITY_BY_ADDRESS = gql`
  query SearchPayrollReportCityByAddress($cityName: String, $prefectureId: Int) {
    client {
      id
      searchPayrollReportCityByAddress(cityName: $cityName, prefectureId: $prefectureId) {
        id
        name
        organizationCode
        prefectureName
      }
    }
  }
`

export type CitiesSearchResult = {
  client: {
    id: number
    searchPayrollReportCities: {
      totalCount: number
      list: PayrollReportCity[]
    }
  }
}
export type CityByAddressSearchResult = {
  client: {
    id: number
    searchPayrollReportCityByAddress: PayrollReportCity
  }
}

export interface PayrollReportCity {
  id: string
  name: string
  prefectureName: string
  organizationCode: string
}
