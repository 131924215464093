import styles from './NotVerified.scss'

export const NotVerified = () => (
  <div className={styles.notVerified}>
    メール送信機能が制限されています。
    <br />
    機能解放をご希望の場合は
    <a
      className={styles.link}
      target="_blank"
      rel="noopener noreferrer"
      href="https://jobcan-lms.zendesk.com/hc/ja/articles/37244518893337"
    >
      こちら
    </a>
    へ
  </div>
)

export const TmNotVerified = () => (
  <div className={styles.notVerified}>
    メール送信機能が制限されています。
    <br />
    機能解放にはご利用の労務HRでのお手続きが必要です。
  </div>
)
